/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 27.05.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LocationClass } from '@core/models/location/location.class'
import { LoaderService } from '@core/services/loader.service'
import * as L from 'leaflet'
import { map } from 'rxjs/operators'

@Injectable()
export class GeocodingService {
  http: HttpClient

  private _geocodeUrl = 'https://nominatim.openstreetmap.org/search?q='
  constructor(
    http: HttpClient,
    private _loaderService: LoaderService,
  ) {
    this.http = http
  }

  geocode(address: string) {
    this._loaderService.display(true)
    return this.http
      .get(
        this._geocodeUrl + encodeURIComponent(address) + '&format=json&polygon=1&addressdetails=1',
      )
      .pipe(
        map((res) => res),
        map((result) => {
          const location = new LocationClass()
          location.address = result[0].display_name
          location.latitude = result[0].lat

          location.longitude = result[0].lon

          const viewPort = result[0].boundingbox
          location.viewBounds = L.latLngBounds(
            {
              lat: viewPort[0],
              lng: viewPort[2],
            },
            {
              lat: viewPort[1],
              lng: viewPort[3],
            },
          )
          this._loaderService.display(false)
          return location
        }),
      )
  }
}
