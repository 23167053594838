import { Injectable } from '@angular/core'
import { BaseService } from '@bases/base.service'
import { API_URL } from '@core/constants/constant.data'

@Injectable()
export class UserManagementService extends BaseService {
  getDiskSpace(): Promise<any> {
    return super.POST(
      {}, // Empty, as keycloak library automatically sends token
      API_URL + '/users/space_used',
    )
  }
}
