import { CommonModule, DecimalPipe } from '@angular/common'
import { HttpClientModule } from '@angular/common/http'
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouterModule } from '@angular/router'
import { CalculationModuleService } from '@components/_panel-left/cms-tab/calculation-modules/calculation-module.service'
import { CMLayersService } from '@components/_panel-left/cms-tab/calculation-modules/service/cm-layers.service'
import { SelectionToolButtonStateService } from '@components/_panel-left/tools-tab/selection-tools/service/selection-tool-button-state.service'
import { SelectionToolUtilsService } from '@components/_panel-left/tools-tab/selection-tools/service/selection-tool-utils.service'
import { SelectionToolService } from '@components/_panel-left/tools-tab/selection-tools/service/selection-tool.service'
import { SideComponent } from '@components/_panel-right/side-panel/side-panel.component'
import { SidePanelService } from '@components/_panel-right/side-panel/side-panel.service'
import { KEYCLOAK_CLIENT_ID, KEYCLOAK_REALM, KEYCLOAK_URL } from '@core/constants/constant.data'
import { BusinessNameService } from '@core/services/business.service'
import { Helper } from '@core/services/helper'
import { InteractionService } from '@core/services/interaction.service'
import { LayersService } from '@core/services/layers.service'
import { LoaderService } from '@core/services/loader.service'
import { SelectionScaleService } from '@core/services/selection-scale.service'
import { ToasterService } from '@core/services/toaster.service'
import { MapService } from '@pages/map/services/map.service'
import { UppercaseFirstLetterPipe } from '@pipes/uppercase-first-letter.pipe'
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular'
import { filter, firstValueFrom, take } from 'rxjs'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app.routes'

function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: KEYCLOAK_URL,
        realm: KEYCLOAK_REALM,
        clientId: KEYCLOAK_CLIENT_ID,
      },
      initOptions: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
      },
      shouldAddToken: (request) => {
        const { method, url } = request

        const isGetRequest = 'GET' === method.toUpperCase()
        const acceptablePaths = ['/geoserver']
        const isAcceptablePathMatch = acceptablePaths.some((path) => url.includes(path))

        return !(isGetRequest && isAcceptablePathMatch)
      },
    })
}

function initApp(LayersService: LayersService) {
  return async () => {
    await firstValueFrom(
      LayersService.initialized$.pipe(
        filter((initialized) => (initialized ? true : false)),
        take(1),
      ),
    )
  }
}

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent, UppercaseFirstLetterPipe, SideComponent],
  providers: [
    MapService,

    // @ToDo: used by MapService
    LoaderService,
    ToasterService,
    LayersService,
    SelectionScaleService,
    CMLayersService,
    SelectionToolService,
    BusinessNameService,
    SelectionToolButtonStateService,
    // @ToDo: used by Service above
    Helper,
    DecimalPipe,
    InteractionService,
    CalculationModuleService,
    SelectionToolUtilsService,
    SidePanelService,

    //Keycloak
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      multi: true,
      deps: [LayersService],
    },
  ],
  imports: [
    // Angular Modules
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,

    // Forms
    FormsModule,
    ReactiveFormsModule,

    // HTTP
    HttpClientModule,

    // Routing
    RouterModule,
    AppRoutingModule,

    //Keycloak
    KeycloakAngularModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
