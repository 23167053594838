<div class="layertabWrapper">
  <div class="header">
    <h3>Layers</h3>
    <button (click)="emitClose.emit()" uk-icon="close"></button>
  </div>

  <div class="layer-list-container">
    <input
      type="text"
      class="uk-input search"
      placeholder="Search Layers..."
      [(ngModel)]="search"
      (ngModelChange)="search$.set($event)"
    />
    <button
      class="uk-button uk-button-primary uk-button-small unselectButton"
      (click)="unselectAllLayers()"
    >
      Unselect all layers
    </button>
    <ul class="layer-list">
      <li>
        <span>
          @if (isConnected) {
            <app-personal-layers></app-personal-layers>
            <app-share-layers></app-share-layers>
          }

          <!-- Use a unique ID for each accordion -->
          <!-- Fixes accordion not closing and opening -->
          @for (category of layersService.category$() | filterCatWithName; track $index) {
            <ul uk-accordion [attr.id]="'accordion-' + $index">
              <li class="uk-open">
                <div (click)="checkGroup(category.name)" class="checkbox">
                  <div
                    [ngClass]="
                      category.used == 1
                        ? 'checked'
                        : category.used == 2
                          ? 'unconfirmed'
                          : 'unchecked'
                    "
                  ></div>
                </div>
                <h3 class="uk-accordion-title">
                  {{ category.name }}
                </h3>

                <div class="uk-accordion-content">
                  @for (
                    layer of filteredLayers$() | filterLayerOnCategory: category;
                    track $index
                  ) {
                    <span class="layerslist">
                      <app-layers-interaction-cell
                        [layer]="layer"
                        [expanded]="expanded"
                      ></app-layers-interaction-cell>
                    </span>
                  }
                </div>
              </li>
            </ul>
          }
        </span>
      </li>
    </ul>
  </div>
</div>
