/// <reference path="typings.d.ts"/>

import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
  window.console.log = () => {}
  window.console.warn = () => {}
  enableProdMode()
}

platformBrowserDynamic().bootstrapModule(AppModule)
