import { Injectable } from '@angular/core'
import { CalculationModuleService } from '@components/_panel-left/cms-tab/calculation-modules/calculation-module.service'

@Injectable()
export class InteractionService {
  summaryResultState = false

  currentCMiD = null
  cmRunningProgress = 0

  constructor(private _calculationModuleService: CalculationModuleService) {}

  deleteCMTask() {
    if (this.cmRunningProgress > 0 && this.currentCMiD != null) {
      const currentCMiD = this.currentCMiD
      this.currentCMiD = null
      return this._calculationModuleService
        .deleteCM(currentCMiD)
        .toPromise()
        .then(() => {
          this.currentCMiD = null
          this._calculationModuleService.cmRunning.next(false)
        })
        .catch((error) => {
          console.warn('deleteCMTask failed', error)
        })
    }
  }
}
