import { environment } from '@env/environment'
import { ScaleLevel } from '../models/scale-level.class'

export const NUTS0 = 'NUTS 0'
export const NUTS1 = 'NUTS 1'
export const NUTS2 = 'NUTS 2'
export const NUTS3 = 'NUTS 3'
export const POPULATION = 'population'
export const LAU2 = 'LAU 2'
export const LAU2_GEOSERVER = 'tbl_lau1_2'
export const HECTARE = 'Hectare'
export const COUNTRY = 'Country'
export const COUNTRY_GEOSERVER = 'boundaries0'
export const REGION = 'Region'
export const REGION_GEOSERVER = 'regions'
export const SUBDIVISION = 'Sub-division'
export const SUBDIVISION_GEOSERVER = 'boundaries1'
export const INITIAL_SCALE = environment.scaleRequest[0].label

export const SCALE_LEVEL_ARRAY: ScaleLevel[] = [
  {
    id: 0,
    displayName: NUTS0,
    apiName: 'nuts0',
    geoServer: POPULATION,
    payloadScale: 'nuts',
  },
  {
    id: 1,
    displayName: NUTS1,
    apiName: 'nuts1',
    geoServer: POPULATION,
    payloadScale: 'nuts',
  },
  {
    id: 2,
    displayName: NUTS2,
    apiName: 'nuts2',
    geoServer: POPULATION,
    payloadScale: 'nuts',
  },
  {
    id: 3,
    displayName: NUTS3,
    apiName: 'nuts3',
    geoServer: POPULATION,
    payloadScale: 'nuts',
  },
  {
    id: 4,
    displayName: LAU2,
    apiName: 'lau2',
    geoServer: LAU2_GEOSERVER,
    payloadScale: 'lau',
  },
  { id: 5, displayName: HECTARE, apiName: 'ha', geoServer: '', payloadScale: 'hectare' },
  { id: 6, displayName: COUNTRY, apiName: '', geoServer: COUNTRY_GEOSERVER, payloadScale: '' },
  { id: 7, displayName: REGION, apiName: '', geoServer: REGION_GEOSERVER, payloadScale: '' },
  {
    id: 8,
    displayName: SUBDIVISION,
    apiName: '',
    geoServer: SUBDIVISION_GEOSERVER,
    payloadScale: '',
  },
]
