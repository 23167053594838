import { CommonModule } from '@angular/common'
import { Component, Input, OnChanges } from '@angular/core'
import { SelectionScaleService } from '@core/services/selection-scale.service'
import { SummaryResultContainerComponent } from './summary-result-container/summary-result-container.component'
import { SummaryResultService } from './summary-result.service'

@Component({
  standalone: true,
  selector: 'app-summary-result',
  templateUrl: 'summary-result.component.html',
  styleUrls: ['summary-result.component.css'],
  providers: [SummaryResultService],
  imports: [
    CommonModule,

    // Components
    SummaryResultContainerComponent,
  ],
})
export class SummaryResultComponent implements OnChanges {
  @Input() summaryResult
  @Input() refSelected

  scale

  constructor(private _selectionScaleService: SelectionScaleService) {}

  ngOnChanges() {
    // scale is used to check if it's correspond to value of scenario explorer
    if (this._selectionScaleService.currentScaleLevel$()) {
      this.scale = this._selectionScaleService.currentScaleLevel$().displayName.toLocaleLowerCase()
    }
  }
}
