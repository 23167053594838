import { Component, Input, OnInit } from '@angular/core'
import { isNullOrUndefinedString } from '@services/core.utilities'

@Component({
  standalone: true,
  selector: 'app-custom-symbology',
  templateUrl: './custom-symbology.component.html',
  styleUrls: ['./custom-symbology.component.css'],
  imports: [],
})
export class CustomSymbologyComponent implements OnInit {
  @Input() symbology: any[]

  ngOnInit() {
    if (!isNullOrUndefinedString(this.symbology)) {
      this.symbology.sort((a, b) => {
        if (+a.value > +b.value) return 1
        if (+a.value < +b.value) return -1
        return 0
      })
    }
  }
}
