@if (expandedState == 'expanded') {
  <div [@feedbackTrigger]="expandedState" class="formWrapper">
    <div class="feedback-content">
      <!-- Feedback form -->
      <form
        [formGroup]="feedbackForm"
        (ngSubmit)="submitFeedbackForm()"
        enctype="multipart/form-data"
        method="post"
        novalidate
      >
        <div class="header">
          <h4>Feedback</h4>
          <a (click)="close($event)" uk-close></a>
        </div>
        <fieldset class="uk-fieldset">
          <div class="uk-margin-small">
            <input
              formControlName="name"
              class="uk-input uk-form-small"
              type="text"
              placeholder="Firstname / Lastname"
            />
          </div>
          <div class="uk-margin-small">
            <input
              formControlName="mail"
              class="uk-input uk-form-small"
              type="email"
              placeholder="E-mail"
            />
          </div>
          <div class="uk-margin-small">
            <input
              formControlName="company"
              class="uk-input uk-form-small"
              type="text"
              placeholder="Company"
            />
          </div>
          <div class="uk-margin-small">
            <select formControlName="type" class="uk-select uk-form-small">
              <option [ngValue]="null">Select feedback type...</option>
              @for (issue_type of issue_type; track $index) {
                <option [ngValue]="issue_type">
                  {{ issue_type.name }}
                </option>
              }
            </select>
          </div>
          <div class="uk-margin-small">
            <select formControlName="level" class="uk-select uk-form-small">
              <option [ngValue]="null">Select feedback level...</option>
              @for (issue_level of issue_levels; track $index) {
                <option [ngValue]="issue_level">
                  {{ issue_level.name }}
                </option>
              }
            </select>
          </div>
          <div class="uk-margin-small">
            <input
              formControlName="title"
              class="uk-input uk-form-small"
              type="text"
              placeholder="Title"
            />
          </div>
          <div class="uk-margin-small">
            <textarea
              formControlName="description"
              class="uk-textarea uk-form-small"
              rows="3"
              placeholder="Description"
            ></textarea>
          </div>
          <div class="uk-margin-small">
            <div class="uk-width-1-1 uk-inline" uk-form-custom="target: true">
              <input
                type="file"
                name="picture"
                #file_input
                (change)="onUploadFile($event)"
                accept="image/*"
              />
              <input
                #file_string
                id="personal-upload-field"
                class="uk-input input-file uk-form-small"
                type="text"
                placeholder="Drag & drop an image here or click to upload"
                disabled
                [value]="fileName"
              />
              <span class="uk-form-icon" uk-icon="icon: upload"></span>
            </div>
            @if (fileName) {
              <p class="uk-text-success">{{ fileName }}</p>
            }
          </div>

          <div class="uk-margin-small">
            <label>
              <input formControlName="isAccepted" type="checkbox" />
              I understand and accept that the information submitted through this form is treated
              via Microsoft Azure and therefore may be shared with third-parties.
            </label>
          </div>

          <div class="uk-margin-small">
            <re-captcha
              siteKey="6Ldb7zcUAAAAADpGmUYGHKDq4HnQ-WqzfqissGje"
              name="captcha"
              (resolved)="onCaptchaResolved($event)"
              (expired)="onCaptchaExpired()"
            ></re-captcha>
            @if (captchaError) {
              <div class="uk-text-danger">Please complete the captcha.</div>
            }
          </div>

          <div class="uk-margin-small">
            <button
              type="submit"
              class="uk-button uk-button-primary uk-width-1-1"
              [disabled]="!feedbackForm.valid || captcha === null"
            >
              Submit Feedback
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  </div>
}
