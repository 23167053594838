/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 2020           Daniel Hunacek - HES-SO              Update creation
 * *
 ******************************************************************/
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BaseService } from '@bases/base.service'
import { LayerClass } from '@components/_panel-left/layers-tab/layers-interaction/layers-interaction.class'
import { LayerInteractionService } from '@components/_panel-left/layers-tab/layers-interaction/layers-interaction.service'
import { SelectionToolService } from '@components/_panel-left/tools-tab/selection-tools/service/selection-tool.service'
import { API_URL, CONSTANT_YEAR } from '@core/constants/constant.data'
import { LAYERS_ARRAY, RASTER_TYPE } from '@core/constants/layers.data'
import { HECTARE, LAU2, NUTS0, NUTS1, NUTS2, NUTS3 } from '@core/constants/scale.data'
import { BlobUrl, UploadedFile } from '@core/models/upload.class'
import { AuthService } from '@core/services/auth.service'
import { MapService } from '@pages/map/services/map.service'
import { environment } from 'environments/environment'
import * as Leaflet from 'leaflet'
import { TileLayer } from 'leaflet'
import { BehaviorSubject } from 'rxjs'
import { Helper } from './helper'
import { LoaderService } from './loader.service'
import { SelectionScaleService } from './selection-scale.service'
import { ToasterService } from './toaster.service'

declare const L: any

@Injectable()
export class UploadService extends BaseService {
  uploadUrl: string = API_URL + '/upload/'

  // For Show and Remove
  //@ToDo change in signal
  uploadedFiles: BehaviorSubject<UploadedFile[]> = new BehaviorSubject<UploadedFile[]>([])
  shareFiles: BehaviorSubject<UploadedFile[]> = new BehaviorSubject<UploadedFile[]>([])
  activePersonalLayers: BehaviorSubject<Object> = new BehaviorSubject<Object>({})

  private _userToken: string
  private _activeLayers: Object = {}

  constructor(
    private _authService: AuthService,
    private _selectionToolsService: SelectionToolService,
    private _selectionScaleService: SelectionScaleService,
    private _helper: Helper,
    private _mapService: MapService,
    private _layerInteractionService: LayerInteractionService,
    protected http: HttpClient,
    protected loaderService: LoaderService,
    protected toasterService: ToasterService,
  ) {
    super(http, loaderService, toasterService)
    this._userToken = _authService.getUserId()
  }

  filterPersonalOrShareLayerWithTypeInProject(layerToFilter) {
    //@ToDo: improve find, return direct the list and not boolean ?
    return LAYERS_ARRAY.find(
      (layer) => layerToFilter.layer === layer.workspaceName,
    ).projects.includes(environment.appName)
  }

  private _showMsg(res: any, success: boolean) {
    this.list()
    this.listShare()
    if (success) this.toasterService.showToaster(res['message'])
    if (!success) this.toasterService.showDefaultErrorToaster(res['status'])
    return success
  }

  /**
   * Add a file to the uploaded files
   * @param file file to add
   * @param layer layer of the file
   * @returns Promise with success of the procedure
   */
  add(file: File, shared: string, layer?): Promise<boolean> {
    let form = new FormData()
    form.append('name', file.name)
    form.append('file', file, file.name)
    form.append('shared', shared)
    form.append('layer', layer.workspaceName)
    form.append('layer_type', layer.layer_type)
    return super
      .POSTunStringify(form, this.uploadUrl + 'add', { headers: new HttpHeaders() })
      .then((response) => this._showMsg(response, true))
      .catch((response) => this._showMsg(response, false))
  }

  /**
   * Delete an uploaded file
   * @param id id of the file to delete
   * @returns Promise with success of the procedure
   */
  delete(id: number | UploadedFile): Promise<boolean> {
    this.remove(id) // remove first
    this._layerInteractionService.removeLayer(id)
    if (typeof id !== 'number') id = (id as UploadedFile).id

    return super
      .DELETE(this.uploadUrl + 'delete', {
        body: { id: id },
      })
      .toPromise()
      .then((response) => this._showMsg(response, true))
      .catch((response) => this._showMsg(response, false))
  }

  /**
   * Create an url to download a uploaded file
   * @param id
   * @param filename name of the file to download
   * @returns Promise with the url to download
   */
  download(id: number | UploadedFile): Promise<string> {
    if (typeof id !== 'number') id = (id as UploadedFile).id

    return super
      .POSTunStringify(
        {
          id: id,
        },
        this.uploadUrl + 'download',
        { responseType: 'blob', headers: new HttpHeaders() },
      )
      .then((data) => URL.createObjectURL(data) as string)
      .catch((err) => {
        console.warn('download in upload service', err)
        return '' // If file does not exist
      })
  }

  clearLayerSelection() {
    for (let activeLayer in this._activeLayers) {
      this.remove(activeLayer as unknown as number)
    }
  }

  /**
   * Remove the layer from the map
   * @param id
   */
  remove(id: number | UploadedFile): void {
    if (typeof id !== 'number') id = (id as UploadedFile).id
    if (!((id as number) in this._activeLayers)) return // if the layer wasn't active
    ;(this._activeLayers[id as number] as TileLayer).removeFrom(this._mapService.getMap())
    delete this._activeLayers[id as number]
    delete this.activePersonalLayers.value[id as number]
    this.activePersonalLayers.next(this.activePersonalLayers.value)
  }

  /**
   * Remove all active layers
   */
  removeAll(): void {
    for (let up in this.uploadedFiles.value) {
      this._layerInteractionService.removeLayer(this.uploadedFiles.value[up].id)
    }

    this.activePersonalLayers.next({})
    this.uploadedFiles.next([])
  }

  /**
   * Export a file from selected area of the map
   * @param layer chosen layer
   * @param schema the schema to export (for later)
   * @param year the year to export
   * @returns Promise with the url to download and a filename
   */
  export(layer: LayerClass): Promise<BlobUrl> {
    let currentScaleLevel = this._selectionScaleService.currentScaleLevel$()
    let nutsOrAreas: Array<string | any>
    let isNuts: boolean = true
    let layerName = layer.workspaceName // override layer with layerName if a value is set in layers-interaction.data.ts
    let uuid = layer.cm_id
    let year = layer.year
    let schema = layer.schema
    let dataType = layer.dataType

    if ([LAU2, NUTS3, NUTS2, NUTS1, NUTS0].indexOf(currentScaleLevel.displayName) > -1) {
      layerName += '_' + currentScaleLevel.apiName
      nutsOrAreas = this._selectionToolsService.nutsIdsSubject.getValue()
    } else if (currentScaleLevel.displayName === HECTARE) {
      layerName += '_' + currentScaleLevel.apiName
      nutsOrAreas = this._helper.getAreasForPayload(
        this._selectionToolsService.areasSubject.getValue(),
      )
      isNuts = false
    } else {
      //@Todo: handle OM4A
      console.warn('Unsupported scale', currentScaleLevel)
    }

    if (uuid == null) {
      // if the layer is not a CM layer
      if (year == null) year = CONSTANT_YEAR
      if (schema == null) schema = RASTER_TYPE

      return super
        .POSTunStringify(
          {
            layers: layerName,
            [isNuts ? 'nuts' : 'areas']: nutsOrAreas,
            schema: schema,
            year: year.toString(),
          },
          this.uploadUrl + `export/${dataType}/${isNuts ? 'nuts' : 'hectare'}`,
          {
            responseType: 'blob',
          },
        )
        .then((data) => {
          return {
            url: URL.createObjectURL(data) as string,
            filename: layerName + `.${dataType != 'csv' ? 'tif' : 'csv'}`,
          } as BlobUrl
        })
        .catch((err) => {
          let errMsg = ''
          if (
            ['UNKNOWN ERROR', 'INTERNAL SERVER ERROR', 'UNKNOWN'].indexOf(
              err.toString().toUpperCase(),
            ) > -1
          ) {
            errMsg = 'An internal error occured.'
          } else if (
            ['Failed retrieving year in database'.toUpperCase()].indexOf(
              err.toString().toUpperCase(),
            ) > -1
          ) {
            errMsg = err.statusText
          } else {
            errMsg = 'This layer cannot be exported.'
          }
          this.toasterService.showToaster(errMsg)
          // this.toasterService.showToaster("Sorry, We can't export this layer");
          return { url: '', filename: '' } as BlobUrl
        })
    } else {
      // if the layer is a cm layer

      // @ToDo : handle layer as CSV result of a CM
      let type = layer.type_of_layer
      let layerName = layer.name
      return this.exportCMresultFile(layer.cm_id, type, layerName)
    }
  }

  exportCMresultFile(uuid: string, type: string, fileName: string) {
    // let extension = ''
    if (type == RASTER_TYPE) {
      fileName = fileName + '.tif'
      uuid = uuid + '.tif'
    }
    return super
      .POSTunStringify(
        {
          uuid: uuid,
          type: type,
        },
        this.uploadUrl + 'export/cmLayer',
        { responseType: 'blob' },
      )
      .then((data) => {
        return {
          url: URL.createObjectURL(data) as string,
          filename: fileName,
        } as BlobUrl //@ToDo: correct
      })
      .catch(() => {
        this.toasterService.showToaster("Sorry, We can't export this layer")
        return { url: '', filename: '' } as BlobUrl
      })
  }

  /**
   * Get the list of the uploaded files
   * @returns Promise with the files
   */
  list(): Promise<UploadedFile[]> {
    return super.POSTunStringify({}, this.uploadUrl + 'list').then((response) => {
      this._addUploadedLayersToLayers(response['uploads'])
      this.uploadedFiles.next(response['uploads'])
      return this.uploadedFiles.getValue()
    })
  }

  /**
   * Get the list of the uploaded files
   * @returns Promise with the files
   */
  listShare(): Promise<UploadedFile[]> {
    return super.POSTunStringify({}, this.uploadUrl + 'listshare').then((response) => {
      this._addUploadedLayersToLayers(response['uploads'])
      this.shareFiles.next(response['uploads'])
      return this.shareFiles.getValue()
    })
  }

  private _addUploadedLayersToLayers(uploads) {
    uploads.map((upload) => {
      if (!this._layerInteractionService.layerExists(upload)) {
        this._layerInteractionService.addNewLayer(
          upload.name,
          upload.layer,
          upload.id,
          upload.layer_type,
        )
      }
    })
  }

  /**
   * Show the layer on the map
   * @param id
   */
  async show(id: number | UploadedFile) {
    const upFile: UploadedFile =
      typeof id === 'number'
        ? this.uploadedFiles.getValue().filter((upload) => upload.id == id)[0]
        : (id as UploadedFile)

    if (upFile.id in this._activeLayers) {
      this.toasterService.showToaster('Layer already active')
      return
    }

    const payload = {
      id: upFile.id,
      user_token: this._userToken,
      layer_id: upFile.layer,
      layer_name: upFile.name,
    }
    this.activePersonalLayers.value[upFile.id as number] = payload
    this.activePersonalLayers.next(this.activePersonalLayers.value)
    if (upFile.name.endsWith('.tif')) {
      // Here, we manually set the token to the request as it is a Get and does not allow custom header variables
      const token = await this._authService.getKeycloakInstance().token // Get the Keycloak token

      //Retrieve max zoom level
      this.http.post<any>(`${API_URL}/upload/maxzoom/${upFile.id}`, {}).subscribe({
        next: (data) => {
          const maxNativeZoom = data.maxZoom || 11 // Fallback to 11 if no maxZoom is provided
          this._activeLayers[upFile.id] = Leaflet.tileLayer(
            `${this.uploadUrl}tiles/{id}/{z}/{x}/{y}?token=${token}`, // Attach token as URL parameter
            {
              id: upFile.id.toString(),
              tms: true,
              maxNativeZoom: maxNativeZoom,
              zIndex: 5, // Prevents layer from being hidden by map
            },
          ).addTo(this._mapService.getMap())
        },
        error: (error) => {
          // Handle errors
          console.warn('Error retrieving max zoom level:', error)
        },
      })
    } else if (upFile.name.endsWith('.csv')) {
      this.http.get(this.uploadUrl + 'csv/' + upFile.id).subscribe((geoData) => {
        for (const feature of (geoData as any).features)
          if (feature.geometry.type === 'MultiPolygon') feature.style.color = feature.style.fill
        // feature.style.fillOpacity = feature.style.size;

        this._activeLayers[upFile.id] = new L.geoJson(geoData, {
          style: (feature) => feature.style, // Seems it need to force style for polygons
          pointToLayer: (feature: any, latlng: Leaflet.LatLng) => {
            if (feature.geometry.type == 'Point' && feature.style.name) {
              // filter out elements without any style.name

              if (feature.style.name == 'circle') {
                // circle marker
                let circleMaker = new L.CircleMarker(latlng, {
                  fillColor: feature.style.fill,
                  color: feature.style.stroke,
                  fillOpacity: 1,
                  weight: 1,
                  // https://github.com/Leaflet/Leaflet/issues/2824
                  radius: +feature.style.size / 2,
                })

                //@ToDo return company name with the same style as the one precompiled in the geoserver
                // Under a custom solution
                if (feature.style.stroke == '#000000' && feature.properties.companyname) {
                  // @ToDo: rename company without g (and in geoserver)
                  // to differentiate between emission sites and company name
                  circleMaker.bindTooltip(feature.properties.companyname, {
                    permanent: true,
                    direction: 'top',
                  })
                }
                return circleMaker

                //@ToDo return piechart with value
              } else if (feature.style.name == 'chart') {
                let svgHtmlPieChart: string = this._constructPieChartSVG(feature.style)

                const svgIcon = Leaflet.divIcon({
                  html: svgHtmlPieChart,
                  className: 'svg-icon',
                  iconSize: [feature.style.size, feature.style.size],
                })
                return Leaflet.marker(latlng, {
                  icon: svgIcon,
                })
              } else {
                // define shape from style name
                let svgHtmlTriangle: string = this._constructTriangleSVG(feature.style)

                let svgIcon: Leaflet.DivIcon = Leaflet.divIcon({
                  // html: svgHtmlType,
                  className: 'svg-icon',
                })

                switch (feature.style.name) {
                  case 'triangle':
                    svgIcon = Leaflet.divIcon({
                      html: svgHtmlTriangle,
                      className: 'svg-icon',
                      iconSize: [feature.style.size, feature.style.size],
                    })

                  case 'square':
                    // @ToDo create square svg (take triangle and svgIcon as example)
                    break
                  case 'pentagon':
                    // @ToDo create pentagon svg (take triangle and svgIcon as example)
                    break
                  case 'hexagon':
                    // @ToDo create hexagon svg (take triangle and svgIcon as example)
                    break
                  case 'octogon':
                    // @ToDo create octogon svg (take triangle and svgIcon as example)
                    // ToDo: check if octogon or octagon
                    break
                }

                return Leaflet.marker(latlng, {
                  icon: svgIcon,
                })
              }
            }
          },
        }).addTo(this._mapService.getMap())
      })
    }
  }

  private _constructPieChartSVG(chart: {
    data: { [key: string]: number }
    size: number
    chartOptions: { [key: string]: { fillColor: string } }
  }): string {
    const data = chart.data
    const chartOptions = chart.chartOptions as {
      [key: string]: { fillColor: string }
    }
    const size = chart.size

    // calculating total sum of all values
    const total = Object.values(data).reduce((sum, value) => sum + value, 0)

    // generating base SVG element
    let svg = `<svg height="${size * 3}" width="${size * 3}" viewBox="0 0 ${size * 2.5} ${
      size * 2.5
    }">`

    // add offset grey circle, simulation of shadow
    svg += `<circle r="${size / 2}" cx="${size * 1.45}" cy="${size * 1.45}" fill="grey"
                  stroke="grey"
                  stroke-width="${size}"
      />`

    // add white background circle, simulation of border
    svg += `<circle r="${size / 2}" cx="${size * 1.25}" cy="${size * 1.25}" fill="white"
                  stroke="white"
                  stroke-width="${size + 8}"
      />`

    let startAngle = 0
    let endAngle = 0

    // constructing pie slices
    Object.keys(data).forEach((value) => {
      // Calculate the end angle of the current slice:
      // - adding the percentage of the current category to the previous end angle
      // - scale it to the range of 0 to 360 degrees
      let percentage = Math.round((data[value] / total) * 100)
      endAngle += (percentage / 100) * 360

      // Constructing a circle for each slice
      // - the *1.25 in cx & cy allows for the pie chart to be centered to its view box
      // each circle is a slice of pie that is add to the previous one
      // recuperates the end angle of the previous slice
      let color = chartOptions[value].fillColor
      svg += `<circle
                  r="${size / 2}"
                  cx="${size * 1.25}"
                  cy="${size * 1.25}"
                  fill="transparent"
                  stroke="${color}"
                  stroke-width="${size}"
                  stroke-dasharray="${(percentage / 100) * Math.PI * size},${Math.PI * size}"
                  stroke-dashoffset="${(-startAngle / 360) * Math.PI * size}"
              />`

      // Update the starting angle for the next slice
      startAngle = endAngle
    })

    // add the end html tag of the svg to close it once it iterated on each slice
    svg += '</svg>'

    return svg
  }

  private _constructTriangleSVG(style: any): string {
    const size = style.size
    const fill = style.fill
    const stroke = style.stroke

    const halfWidth = size / 2
    const height = (Math.sqrt(3) / 2) * size

    return `<svg height="${height * 2}" width="${size * 2}" xmlns="http://www.w3.org/2000/svg">
          <polygon points="0,${height} ${size},${height} ${halfWidth},0" style="fill:${fill};stroke:${stroke}" />
        </svg>`
  }
}
