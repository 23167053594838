import { Injectable } from '@angular/core'
import { KeycloakService } from 'keycloak-angular'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private _keycloakService: KeycloakService) {}

  // Check if the user is logged in
  async isLoggedIn(): Promise<boolean> {
    return await this._keycloakService.isLoggedIn()
  }

  getKeycloakInstance(): any | null {
    const result = this._keycloakService.getKeycloakInstance()
    return result ? result : null
  }

  // Get the username of the authenticated user
  getUserName(): string | null {
    const token = this._keycloakService.getKeycloakInstance().tokenParsed
    return token ? token['preferred_username'] : null
  }

  getFirstname(): string | null {
    const token = this._keycloakService.getKeycloakInstance().tokenParsed
    return token ? token['given_name'] : null
  }

  getLastname(): string | null {
    const token = this._keycloakService.getKeycloakInstance().tokenParsed
    return token ? token['family_name'] : null
  }

  // Get the user email of authenticated user
  getUserEmail(): string | null {
    const token = this._keycloakService.getKeycloakInstance().tokenParsed
    return token ? token['email'] : null
  }

  // Get the user ID (sub) of the authenticated user
  getUserId(): string | null {
    const token = this._keycloakService.getKeycloakInstance().tokenParsed
    return token ? token['sub'] : null // 'sub' field contains the user ID
  }

  // Trigger the login flow
  login(): void {
    // Adds loggedIn flag, used to show a toaster if user logs out.
    const redirectUri = `${window.location.href}?loggedIn=true`
    this._keycloakService.login({ redirectUri: redirectUri })
  }

  // Trigger the logout flow
  logout(): Promise<void> {
    // Adds loggedOut flag, used to show a toaster if user logs out.
    // Required as keycloak logout redirects to a logout page, then goes back to app
    const redirectUri = `${window.location.href}?loggedOut=true`
    return this._keycloakService.logout(redirectUri)
  }
}
