import { Injectable } from '@angular/core'
import { BaseService } from '@bases/base.service'
import { API_URL } from '@core/constants/constant.data'

@Injectable()
export class SummaryResultService extends BaseService {
  getSummaryResultWithIds(payload: any): Promise<any> {
    return super.POST(payload, API_URL + '/stats/layers/nuts-lau')
  }

  getSummaryResultWithMultiAreas(payload: any): Promise<any> {
    return super.POST(payload, API_URL + '/stats/layers/hectares')
  }

  getSummaryResultPersonalLayers(payload) {
    return super.POST(payload, API_URL + '/stats/personal-layers')
  }
}
