/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 27.05.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/
import { HttpClient } from '@angular/common/http'
import { Injectable, OnDestroy, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { BaseService } from '@bases/base.service'
import { CMLayersService } from '@components/_panel-left/cms-tab/calculation-modules/service/cm-layers.service'
import { SelectionToolButtonStateService } from '@components/_panel-left/tools-tab/selection-tools/service/selection-tool-button-state.service'
import { SelectionToolService } from '@components/_panel-left/tools-tab/selection-tools/service/selection-tool.service'

import { GEOSERVER_FEATURE_INFO_URL, MAPS_ORDER } from '@core/constants/constant.data'
import {
  COUNTRY,
  COUNTRY_GEOSERVER,
  HECTARE,
  LAU2,
  LAU2_GEOSERVER,
  POPULATION,
  REGION,
  REGION_GEOSERVER,
  SUBDIVISION,
  SUBDIVISION_GEOSERVER,
} from '@core/constants/scale.data'
import { LayersService } from '@core/services/layers.service'
import { LoaderService } from '@core/services/loader.service'
import { SelectionScaleService } from '@core/services/selection-scale.service'
import { ToasterService } from '@core/services/toaster.service'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { environment } from 'environments/environment'
import * as L from 'leaflet'
import { BehaviorSubject, Subject } from 'rxjs'
import LatLng = L.LatLng

@Injectable()
export class MapService extends BaseService implements OnInit, OnDestroy {
  //@ToDo change in signal all behavior
  private _clickEventSubject = new BehaviorSubject<void>(null) // Observable source for click
  clickEventSubjectObs = this._clickEventSubject.asObservable() // Observable stream
  private _drawCreatedSubject = new Subject<void>()
  drawCreatedSubjectObs = this._drawCreatedSubject.asObservable()
  layerArray: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([])
  zoomLevel: BehaviorSubject<number> = new BehaviorSubject<number>(5)

  cmRunning = false

  private _map: L.DrawMap
  private _areaNutsSelectedLayer: any
  private _osmLayerName = 'Road'
  private _satelliteLayerName = 'Satellite'
  private _clickAccuracy = 100

  constructor(
    http: HttpClient,
    loaderService: LoaderService,
    toasterService: ToasterService,
    private _layersService: LayersService,
    private _selectionScaleService: SelectionScaleService,
    private _cmLayerService: CMLayersService,
    private _selectionToolService: SelectionToolService,
    private _selectionToolButtonStateService: SelectionToolButtonStateService,
    private _router: Router,
  ) {
    super(http, loaderService, toasterService)
  }
  ngOnInit(): void {
    this.layerArray.next(this._layersService.layersArray.keys())
  }

  ngOnDestroy(): void {
    this._clickEventSubject.complete()
  }

  //@ToDo : delete this getter
  getMap(): L.DrawMap {
    return this._map
  }

  setProjectInfoOpen() {
    if (this._router.url !== '') {
      this._router.navigate([''])
    }
  }
  setProjectInfoClose() {
    if (this._router.url !== 'map') {
      this._router.navigate(['map'])
    }
  }

  recenter() {
    this._map.setView(
      L.latLng(environment.centerMap[0], environment.centerMap[1]),
      environment.zoom,
    )
  }

  private _clickCursorUpdate() {
    if (!this._clickEventSubject.closed) {
      this._clickEventSubject.next()
    }
  }

  private _drawCreatedUpdate() {
    this._drawCreatedSubject.next()
  }

  // Retrieve all map events
  private _retrieveMapEvent(): void {
    this._map.on('click', (event: L.LeafletMouseEvent) => {
      this._onClickEvent(event)
    })
    this._map.on('baselayerchange', (event: L.LayersControlEvent) => {
      this._onBaseLayerChange(event)
    })
    this._map.on('zoomstart', () => {})
    this._map.on('zoomend', () => {
      this._onZoomEnd()
    })
    this._map.on('LayersControlEvent', () => {})
    this._map.on('layeradd', () => {})
    this._map.on('didUpdateLayers', () => {})
    this._map.on('overlayadd', () => {})
    this._map.on(L.Draw.Event.CREATED, (e) => {
      this._onDrawCreated(e)
    })
    this._map.on(L.Draw.Event.EDITED, () => {})
    this._map.on(L.Draw.Event.DRAWSTART, () => {
      this._onDrawStart()
    })
    this._map.on(L.Draw.Event.EDITSTART, () => {
      this._onDrawEditStart()
    })
    this._map.on(L.Draw.Event.EDITSTOP, () => {
      this._onDrawEditStop()
    })
    this._map.on(L.Draw.Event.DELETED, () => {
      this._onDrawDeleted()
    })
  }

  // Event functions
  private _onDrawCreated(e) {
    this._selectionToolService.drawCreated(e, this._selectionScaleService.currentScaleLevel$())
    this._selectionToolService.isPolygonDrawer = false
    this._drawCreatedUpdate()
  }
  private _onDrawStart() {
    this._selectionToolService.isActivate = true
    this._selectionToolService.buttonClearAll.next(true) // enable button when the drawing starts
  }
  private _onDrawEditStart() {
    this._selectionToolService.isActivate = true
  }
  private _onDrawEditStop() {
    this._selectionToolService.setAreas()
    this._selectionToolService.isActivate = false
  }
  private _onDrawDeleted() {
    this._selectionToolService.clearAll()
  }
  private _onZoomEnd() {
    this.zoomLevel.next(this._map.getZoom())
  }
  private _onBaseLayerChange(e: L.LayersControlEvent) {
    // in this part we manage the selection scale then we refresh the layers
    const currentScaleLevel = e.name
    if (
      currentScaleLevel !== this._osmLayerName &&
      currentScaleLevel !== this._satelliteLayerName
    ) {
      this._selectionToolService.clearAll()

      this._selectionScaleService.setScaleLevelWitDisplayName(currentScaleLevel)
    }
  }
  private _onClickEvent(e: L.LeafletMouseEvent) {
    if (this.cmRunning) {
      this.toasterService.showDangerToaster(
        'To run the calculation module (CM) for your new selection, STOP CM and RUN it again.',
      )
    }
    if (!this._selectionScaleService.currentScaleLevel$()) {
      return
    }
    if (this._selectionScaleService.currentScaleLevel$().displayName === HECTARE) {
      return
    }
    if (this._selectionToolService.isPolygonDrawer) {
      return
    }

    this._selectionToolService.activateClickSelectionTool()

    this._selectionToolButtonStateService.enable(true) // opens the selection tools

    // automatic cursor tool selection doesn't work if polygon draw is activated
    if (!this._selectionToolService.isPolygonDrawer) {
      this._clickCursorUpdate() // automatic cursor tool selection
    }
    // check if the selection tool is activate
    if (this._selectionScaleService.currentScaleLevel$().displayName === COUNTRY) {
      this._getNutsGeometryFromCountryAfrica(e.latlng)
    } else if (this._selectionScaleService.currentScaleLevel$().displayName === REGION) {
      this._getNutsGeometryFromRegionAfrica(e.latlng)
    } else if (this._selectionScaleService.currentScaleLevel$().displayName === SUBDIVISION) {
      this._getNutsGeometryFromSubregionAfrica(e.latlng)
    } else if (this._selectionScaleService.currentScaleLevel$().displayName === HECTARE) {
      if (this._layersService.getIsReadyToShowFeatureInfo() === true) {
        // ! 27.11.2024 this method does not exist on hotmaps
        //this.getHectareGeometryFromClick(e.latlng, this._selectionScaleService.getScaleValue())
      }
    } else if (this._selectionScaleService.currentScaleLevel$().displayName === LAU2) {
      this._getNutsGeometryFromLau2(e.latlng)
    } else {
      //other NUTS
      this._getNutsGeometryFromNuts(e.latlng)
    }
  }

  // NUTS management
  private _getNutsGeometryFromNuts(latlng: LatLng): any {
    const currentScaleLevel = this._selectionScaleService.currentScaleLevel$().id
    let bbox = latlng.toBounds(this._clickAccuracy).toBBoxString()
    bbox =
      bbox +
      '&CQL_FILTER=' +
      'stat_levl_=' +
      currentScaleLevel +
      ' AND ' +
      'date=' +
      '2015' +
      '-01-01Z'
    const action = POPULATION
    const url =
      GEOSERVER_FEATURE_INFO_URL +
      action +
      '&STYLES&LAYERS=' +
      environment.prefixWorkspaceName +
      action +
      '&INFO_FORMAT=application/json&FEATURE_COUNT=50' +
      '&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101&BBOX=' +
      bbox
    return this._getAreaFromScale(url)
  }
  // LAU management;
  private _getNutsGeometryFromLau2(latLng: LatLng): any {
    const bbox = latLng.toBounds(this._clickAccuracy).toBBoxString()
    const action = LAU2_GEOSERVER
    const url =
      GEOSERVER_FEATURE_INFO_URL +
      action +
      '&STYLES&LAYERS=hotmaps:' +
      action +
      '&INFO_FORMAT=application/json&FEATURE_COUNT=50' +
      '&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101&BBOX=' +
      bbox
    return this._getAreaFromScale(url)
  }
  private _getNutsGeometryFromCountryAfrica(latLng: LatLng): any {
    let bbox = latLng.toBounds(this._clickAccuracy).toBBoxString()
    const action = COUNTRY_GEOSERVER
    const url =
      GEOSERVER_FEATURE_INFO_URL +
      action +
      '&STYLES&LAYERS=' +
      environment.prefixWorkspaceName +
      action +
      '&INFO_FORMAT=application/json&FEATURE_COUNT=50' +
      '&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101&BBOX=' +
      bbox
    return this._getAreaFromScale(url)
  }
  private _getNutsGeometryFromRegionAfrica(latLng: LatLng): any {
    let bbox = latLng.toBounds(this._clickAccuracy).toBBoxString()
    const action = REGION_GEOSERVER
    const url =
      GEOSERVER_FEATURE_INFO_URL +
      action +
      '&STYLES&LAYERS=' +
      environment.prefixWorkspaceName +
      action +
      '&INFO_FORMAT=application/json&FEATURE_COUNT=50' +
      '&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101&BBOX=' +
      bbox
    return this._getAreaFromScale(url)
  }
  private _getNutsGeometryFromSubregionAfrica(latlng: LatLng): any {
    let bbox = latlng.toBounds(this._clickAccuracy).toBBoxString()
    const action = SUBDIVISION_GEOSERVER
    const url =
      GEOSERVER_FEATURE_INFO_URL +
      action +
      '&STYLES&LAYERS=' +
      environment.prefixWorkspaceName +
      action +
      '&INFO_FORMAT=application/json&FEATURE_COUNT=50' +
      '&X=50&Y=50&SRS=EPSG:4326&WIDTH=101&HEIGHT=101&BBOX=' +
      bbox
    return this._getAreaFromScale(url)
  }

  private _getAreaFromScale(url): any {
    return this.http.get(url).subscribe((res) => {
      this.selectAreaWithNuts(res)
    })
  }

  selectAreaWithNuts(areaSelected: any) {
    // test if polygon tool is activated in order to avoid selecting a nuts during a polygon drawing
    if (!this._selectionToolService.isPolygonDrawer) {
      // remove the layer if there is one
      this._removeAreaSelectedLayer()
      // create an other selection only if this is a new area or if no area is actually selected (highlighted)
      if (isNullOrUndefinedString(areaSelected)) return
      const areaNutsSelectedLayer = L.geoJSON(areaSelected)
      if (this._selectionToolService.containLayer(areaNutsSelectedLayer) == 0) {
        this._selectionToolService.removeLayerFromMultiSelectionLayers(areaNutsSelectedLayer)
      } else if (this._selectionToolService.containLayer(areaNutsSelectedLayer) == 1) {
        this._selectionToolService.addToMultiSelectionLayers(areaNutsSelectedLayer)
      } else {
        return
      }
    }
  }

  private _removeAreaSelectedLayer() {
    if (this._areaNutsSelectedLayer) {
      this._map.removeLayer(this._areaNutsSelectedLayer)
      delete this._areaNutsSelectedLayer

      // disable buttons when layer is removed
      this._selectionToolService.buttonClearAll.next(false)
      this._selectionToolService.buttonLoadResultStatus.next(false)
    }
  }

  private _getSelectionScaleMenu() {
    const titleDiv = L.DomUtil.create('div', 'leaflet-control-layers-title')
    const tooltip = this._selectionScaleService.getTooltipMenu()

    if (tooltip.trim()) {
      titleDiv.innerHTML = `Selectable areas <span style='width: 12px; position: relative; top: -1px;' uk-icon='icon: info;' title="${tooltip}"></span>`
    } else {
      titleDiv.innerHTML = 'Selectable areas'
    }

    const scaleControlContainer = this._selectionScaleService
      .getSelectionScaleMenu(this._map)
      .getContainer()

    scaleControlContainer.insertBefore(titleDiv, scaleControlContainer.firstChild)

    //@ToDo:matomo
    // add event tracking to the selectable areas
    // let selectableAreas = document.getElementsByClassName(
    //   'leaflet-control-layers-base',
    // )[0] as HTMLElement
    // let areaChose = ''
    // selectableAreas.childNodes.forEach((area) => {
    //   areaChose = area.childNodes[0].childNodes[1].textContent
    //   ;(area.childNodes[0].childNodes[0] as HTMLElement).setAttribute(
    //     'onclick',
    //     "_paq.push(['trackEvent', 'Selectable Areas', 'Change area', '" + areaChose + "']);",
    //   )
    // })
  }

  showOrRemoveLayer(action: string, order: number) {
    this._layersService.showOrRemoveLayer(action, this._map, order)
  }
  showOrRemoveLayerWithBoolean(action: string, order: number, boolean: boolean) {
    boolean
      ? this._layersService.showLayer(action, this._map, order)
      : this._layersService.removeLayer(action, this._map)
  }

  clearLayerSelection() {
    this._layersService.removeAllLayers()
  }

  setupMapService(map: L.DrawMap) {
    // set the map to the services that needs to get an instance
    this._map = map
    this._getSelectionScaleMenu()
    this._addLayersControl()
    this._retrieveMapEvent()
    this._layersService.layersLeaflet.addTo(this._map)
    this._cmLayerService.layersCM.addTo(this._map)
    this._selectionToolService.multiSelectionLayers.addTo(this._map)
  }

  private _addLayersControl() {
    // OSM Tile Layer
    const osmLayer = L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      zIndex: MAPS_ORDER,
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>,' +
        ' Tiles courtesy of <a href="https://hot.openstreetmap.org/" target="_blank">Humanitarian OpenStreetMap Team</a>',
    })

    // Satellite Tile Layer
    const satelliteLayerUrl =
      'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}'
    const satelliteLayer = L.tileLayer(satelliteLayerUrl, {
      attribution:
        'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC,' +
        ' USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan,',
    })

    // Reference Layer on top of Satellite
    const referenceLayerUrl =
      'https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}'
    const referenceLayer = L.tileLayer(referenceLayerUrl, {
      zIndex: MAPS_ORDER,
      attribution:
        'Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC,' +
        ' USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan,',
    })

    // Group layers to have both images and names
    const satelliteGroupLayer = L.layerGroup([satelliteLayer, referenceLayer]).addTo(this._map)

    // Add control with buttons
    const baseLayers = {
      [this._satelliteLayerName]: satelliteGroupLayer,
      [this._osmLayerName]: osmLayer,
    }

    // title for maps choice
    const titleBackgroundMaps = L.DomUtil.create('div', 'leaflet-control-layers-title')
    titleBackgroundMaps.innerHTML = 'Background maps'

    const backgroundMapCL = L.control.layers(baseLayers, null, {
      collapsed: false,
      position: 'topright',
    })
    backgroundMapCL.addTo(this._map)
    // insert title before the first child backgroundMapCL container
    backgroundMapCL
      .getContainer()
      .insertBefore(titleBackgroundMaps, backgroundMapCL.getContainer().firstChild)

    //@ToDo matomo
    // add event tracking to the selectable maps
    // let mapChose = ''
    // let backGroundMaps = document.getElementsByClassName(
    //   'leaflet-control-layers-base',
    // )[1] as HTMLElement
    // backGroundMaps.childNodes.forEach((map) => {
    //   mapChose = map.childNodes[0].childNodes[1].textContent
    //   ;(map.childNodes[0].childNodes[0] as HTMLElement).setAttribute(
    //     'onclick',
    //     "_paq.push(['trackEvent', 'Selectable Maps', 'Change map', '" + mapChose + "']);",
    //   )
    // })
  }

  setLayersSubject() {
    const layers = []
    this._layersService.layersArray.keys().map((layersName) => {
      layers.push(layersName)
    })
    this.layerArray.next(layers)
  }

  displayCustomLayerFromCM(directory, type) {
    this._cmLayerService.addOrRemoveLayerWithAction(directory, type, this._map)
  }

  // Used to show if the user is using the dev/local API
  setIsDevOrLocalHost() {
    var isDevCondition = ['dev']
    var isLocalhostCondition = ['localhost']
    if (isDevCondition.some((url) => environment.apiUrl.toLowerCase().includes(url))) {
      return 'Development'
    } else if (isLocalhostCondition.some((url) => environment.apiUrl.toLowerCase().includes(url))) {
      return 'Localhost'
    } else {
      return ''
    }
  }
}
