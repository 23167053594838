import { Component, Input, OnInit } from '@angular/core'
import { ChartComponent } from '@components/chart/chart.component'
import { ENERGY_MIX_OPTIONS } from './electricity-mix.data'

/**
 * This component is used solely to display the Pie chart of the Electricity C02 Emissions - Country Average Layer
 * To see the Chart in the frontend you need to :
 *    - Use NUTS 0
 *    - Select the Electricity C02 Emissions - Country Average layer
 *    - Run any CM
 *  This component will display in the right panel
 */

@Component({
  standalone: true,
  selector: 'app-electricity-mix',
  templateUrl: './electricity-mix.component.html',
  styleUrls: ['./electricity-mix.component.css'],
  imports: [
    // Components
    ChartComponent,
  ],
})
export class ElectricityMixComponent implements OnInit {
  @Input() labels
  @Input() datasets
  @Input() isLoading

  options: any
  type: any = 'pie'

  constructor() {}

  ngOnInit() {
    this.options = ENERGY_MIX_OPTIONS
  }
}
