<div class="snapshotsHeader">
  <h4>Snapshots</h4>
  <div>
    <a (click)="refresh()">Refresh</a>
    <a (click)="openSave()">Save new</a>
  </div>
</div>

@if (newOpen) {
  <div class="saveDiv">
    <input type="text" [(ngModel)]="newSnapshot" />
    <a (click)="save()">Save</a>
  </div>
}

<div class="tableWrapper">
  <table class="uk-table uk-table-hover uk-table-striped" #tblBody>
    <tbody>
      <tr>
        <th>Name</th>
        <th class="uk-text-left">Last date</th>
        <th class="uk-text-center"></th>
      </tr>
      @for (snapshot of snapshots$(); track $index) {
        <tr>
          <td>{{ snapshot.name }}</td>
          <td class="uk-text-leftt">{{ snapshot.date | date: 'yyyy/MM/dd' }}</td>
          <td class="uk-text-center">
            <i class="flaticon-success iButton" (click)="apply(snapshot)"></i>
            <i class="flaticon-trash iButton" (click)="delete(snapshot)"></i>
          </td>
        </tr>
      }
    </tbody>
  </table>
</div>
