import { Injectable } from '@angular/core'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { LAYERS_INDICATORS_BUSINESS_NAME } from '../constants/layers-indicators-business-name.data'

@Injectable()
export class BusinessNameService {
  getReadableName(name: string): string {
    const business = LAYERS_INDICATORS_BUSINESS_NAME.filter((x) => x.api_name === name)[0]
    if (isNullOrUndefinedString(business)) {
      return name
    }
    return business.business_name
  }
}
