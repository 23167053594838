@for (layer of results.layers | filterLayers: refSelected; track $index) {
  <tr class="table-level-one">
    <th colspan="2">{{ layer.name | layerName }}</th>
  </tr>
  @for (value of layer.values; track $index) {
    <!-- industrial sites excess heat under 100 has value NULL -->
    @if (value.value !== null) {
      <tr class="table-level-two">
        <td class="info-column">{{ value.name | businessName }}</td>
        <td class="uk-align-left uk-align-center value-column">
          @if (isNumber(value.value)) {
            {{ value.value | number: '1.0-2' | numberformat }}
            {{ value.unit | businessName }}
          } @else if (value.value == 'nan') {
            -
          } @else {
            {{ value.value }}
          }
        </td>
      </tr>
    }
  }
}

@for (layer of results.no_data_layers; track $index) {
  <tr class="table-level-one warning">
    <th colspan="2"><span uk-icon="warning"></span> No data for "{{ layer | layerName }}"</th>
  </tr>
}
