<div class="layertabWrapper">
  <div class="header">
    <h3>Scenario explorer</h3>
    <button (click)="emitClose.emit()" uk-icon="close"></button>
  </div>

  <div class="scenarioContainer">
    @if (apiScenarioRespond$ | async) {
      <h4 class="uk-heading-bullet main_title input_title">Model</h4>
      <select
        [disabled]="isDataLoading$ | async"
        [disabled]="isModelDetailsLoading$ | async"
        class="uk-select"
        (change)="selectModel()"
        id="model-select"
        [(ngModel)]="modelSelected"
      >
        @for (model of modelsList; track $index) {
          <option [value]="model" selected="modelSelected">
            {{ model }}
          </option>
        }
      </select>

      <h4 class="uk-heading-bullet main_title input_title">Definition</h4>
      @if (isModelDetailsLoading$ | async) {
        <div class="loader" uk-spinner class="ukSpinnerModel"></div>
      } @else {
        @if (definition) {
          @if (definition.variables.length > 0) {
            <label for="variable-select">Variable</label>
            <select
              class="uk-select"
              id="variable-select"
              [(ngModel)]="variableSelected"
              [disabled]="isDataLoading$ | async"
              (change)="clearMap()"
            >
              @for (variable of definition.variables; track $index) {
                <option [value]="variable">
                  {{ variable }}
                </option>
              }
            </select>
          }

          @if (definition.regions.length > 0) {
            <label for="region-select">Region scale</label>
            <select
              class="uk-select"
              id="region-select"
              [(ngModel)]="regionSelected"
              [disabled]="isDataLoading$ | async"
              (change)="clearMap()"
            >
              @for (region of definition.regions; track $index) {
                <option [value]="region">
                  {{ region }}
                </option>
              }
            </select>
          }

          @if (definition.years.length > 0) {
            <label for="year-select">Year</label>
            <select
              class="uk-select"
              id="year-select"
              [(ngModel)]="yearSelected"
              [disabled]="isDataLoading$ | async"
              (change)="clearMap()"
            >
              @for (year of definition.years; track $index) {
                <option [value]="year">
                  {{ year }}
                </option>
              }
            </select>
          }
        }

        <button class="uk-button uk-button-primary" (click)="getData()">
          @if (isDataLoading$ | async) {
            <div class="loader" uk-spinner></div>
          }
          Visualize data
        </button>

        @if (showLegend$ | async) {
          <div class="uk-card uk-card-default uk-card-body">
            <h4 class="uk-card-title">Legend</h4>
            @for (element of legendContent; track $index) {
              <div class="card-content">
                <div [ngStyle]="{ 'background-color': element.color }" class="legend"></div>
                {{ element.label }}
              </div>
            }
          </div>
        }
      }
    }

    <p class="errorMessage">
      {{ errorMessage$.getValue() }}
    </p>
    @if (!(apiScenarioRespond$ | async)) {
      <button class="uk-button uk-button-primary" (click)="loadModels()">Retry</button>
    }
  </div>
</div>
