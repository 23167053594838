import { Injectable } from '@angular/core'
import { BaseService } from '@bases/base.service'
import { API_URL, CM_LAYERS_ORDER } from '@core/constants/constant.data'
import { RASTER_TYPE, VECTOR_TYPE } from '@core/constants/layers.data'
import { Dictionary } from '@core/models/dictionary.class'
import { isNullOrUndefinedString } from '@services/core.utilities'
import * as L from 'leaflet'
import * as shp from 'shpjs'

@Injectable()
export class CMLayersService extends BaseService {
  layersCM = new L.FeatureGroup()

  private _cmLayersArray = new Dictionary([{ key: null, value: null }])
  private _layerErrorMessage = `<span class="uk-text-break">Something went wrong. <br> The layer could not be retrieved from the CM and is not showing on the map.</span>`

  addOrRemoveLayerWithAction(directory, type, map: any) {
    if (!this._cmLayersArray.containsKey(directory)) {
      this._addLayerWithAction(directory, type)
    } else {
      this._removeLayer(directory, type)
    }
    map.fireEvent('didUpdateLayers', this._cmLayersArray)
  }

  private _removeLayer(id, type) {
    // we get the layer we want to remove
    const layer = this._cmLayersArray.value(id)
    if (type === VECTOR_TYPE) {
      layer.clearLayers()
    } else if (type === RASTER_TYPE) {
      this.layersCM.removeLayer(layer)
    }
    // we remove this layer from map
    // we destroy the layer
    this._cmLayersArray.remove(id)
  }

  private _addLayerWithActionRaster(directory: string) {
    this.http.get<any>(`${API_URL}/cm/maxzoom/${directory}`).subscribe({
      next: (data) => {
        const maxNativeZoom = data.maxZoom || 14 // Fallback to 14 if no maxZoom is provided

        let layer = L.tileLayer(`${API_URL}/cm/tiles/${directory}/{z}/{x}/{y}`, {
          zIndex: CM_LAYERS_ORDER,
          maxNativeZoom: maxNativeZoom,
          tms: true,
        })

        layer.addTo(this.layersCM)
        this._cmLayersArray.add(directory, layer)
      },
      error: (error) => {
        console.warn('_addLayerWithActionVector', error)
        this.toasterService.showDangerToaster(this._layerErrorMessage)
      },
    })
  }

  private _addLayerWithActionVector(directory) {
    shp(API_URL + '/cm/files/' + directory)
      .then((data) => {
        let layer
        layer = new L.GeoJSON(data as any, {
          onEachFeature: this.onEachFeature,
          style: (feature) => {
            let color = '#FFA500'
            let fillColor = '#FFA500'
            let fillOpacity = 0.5

            if (!isNullOrUndefinedString(feature.properties['color']))
              color = feature.properties['color']
            if (!isNullOrUndefinedString(feature.properties['fillColor']))
              fillColor = feature.properties['fillColor']
            if (!isNullOrUndefinedString(feature.properties['opacity']))
              //@ToDo: why is not : fillOpacity = feature.properties['opacity']
              color = feature.properties['opacity']

            return {
              color: color,
              fillColor: fillColor,
              fillOpacity: fillOpacity,
            }
          },
        })

        layer.addTo(this.layersCM)
        this._cmLayersArray.add(directory, layer)
      })
      .catch((error) => {
        console.warn('_addLayerWithActionVector', error)
        this.toasterService.showDangerToaster(this._layerErrorMessage)
      })
  }

  private _addLayerWithAction(directory, type) {
    if (type === RASTER_TYPE) {
      this._addLayerWithActionRaster(directory)
    } else if (type === VECTOR_TYPE) {
      this._addLayerWithActionVector(directory)
    }
  }

  onEachFeature(feature, layer) {
    let html = ''
    Object.keys(feature.properties).map((prop) => {
      if (prop != 'color' && prop != 'fillColor' && prop != 'opacity') {
        html += '<bold>' + prop + ':</bold>'
        html += '<span>' + feature.properties[prop] + '</span><br />'
      }
    })
    layer.bindPopup(html)
  }

  clearAll() {
    this.layersCM.clearLayers()
  }
}
