<div [class.isLoggedIn]="isLoggedIn" [class.isLoggedOut]="!isLoggedIn" class="userWrapper">
  @if (!isLoggedIn) {
    <a (click)="login()" class="uk-icon-link">
      <!-- @ToDo: matomo -->
      <button
        uk-icon="user"
        onclick="/*_paq.push(['trackEvent', 'Login', 'Login Button Click', 'user try to login']);*/"
      ></button>
    </a>
  } @else {
    <a href="#modal-account" class="uk-icon-link" uk-toggle>
      <span class="link-right">{{ initial }}</span>
    </a>
  }
</div>

<div id="modal-account" uk-modal>
  <div class="uk-modal-dialog uk-modal-body">
    <button class="uk-modal-close-default" type="button" uk-close></button>

    <div>
      <h3>Information</h3>
      <p><strong>Name:</strong> {{ name }}</p>
      <p><strong>Email:</strong> {{ email }}</p>
      <a href="#modal-gdpr" uk-toggle (click)="$event.preventDefault()">Privacy Policy</a>
    </div>

    <div>
      <a (click)="editAccount()">Edit account</a>
    </div>

    <div>
      <a (click)="logout()">Logout</a>
    </div>

    <div id="modal-gdpr" uk-modal>
      <div class="uk-modal-dialog uk-modal-body privacy-policy-modal">
        <button class="uk-modal-close-default" type="button" uk-close></button>
        <app-gdpr></app-gdpr>
      </div>
    </div>
  </div>
</div>
