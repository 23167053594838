<div class="container-panel-right" [@panelWidthTrigger]="expandedState$()" [hidden]="!expanded$()">
  <div class="title-panel-right">
    <button
      [@iconTrigger]="expandedState$()"
      (click)="toggleExpandedState()"
      uk-icon="close"
    ></button>
    <span>{{ title }}</span>
  </div>

  <div class="results-container">
    <app-result-manager
      [personalLayerPayload]="personalLayerPayload"
      [summaryPayload]="summaryPayload"
      [cmPayload]="cmPayload"
      [energyMixPayload]="energyMixPayload"
      [scenarioExplorerData]="scenarioResultsSelected"
    ></app-result-manager>
  </div>
</div>
