import { Injectable } from '@angular/core'
import { CALCULATION_MODULE_CATEGORY } from '@core/constants/constant.data'
import { AngularCsv as Angular2Csv } from 'angular-csv-ext/dist/Angular-csv'
import { DEFAULT_DROP_DOWN_BUTTON } from '../result-manager.data'

@Injectable()
export class ExportDataService {
  constructor() {}

  exportData(data, tabSelectedName: string, cmSessionName, refSelected) {
    const options = {
      fieldSeparator: ';',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: true,
      showTitle: false,
      useBom: true,
    }
    // append cm session name to file name of exported CSV if CM is in the indicator results
    // otherwise keep random name
    let file_name
    // check if no CM in results
    // second condition checks if the dropdown (refSelected), in results summary, is NOT overall or calculation module
    if (
      cmSessionName == undefined ||
      ![DEFAULT_DROP_DOWN_BUTTON, CALCULATION_MODULE_CATEGORY].includes(refSelected.toLowerCase())
    ) {
      // if no CM
      file_name = 'report_' + tabSelectedName + '_' + this.generateRandomName()
    } else {
      // CM in results
      file_name = 'report_' + tabSelectedName + '_' + cmSessionName
    }
    // @ToDo take into account the dropdown!

    new Angular2Csv(data, file_name, options)
  }

  generateRandomName(): string {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return Math.random().toString(36).substr(2, 9)
  }
}
