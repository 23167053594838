/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 07.07.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/

import { environment } from 'environments/environment'
import * as L from 'leaflet'
import 'leaflet-draw'

export const MAX_SURFACE_VALUE_CM = 64000000 //640000 changed for om4a --> to define different value of EU and Africa use environment or condition on continent in environment
export const CONSTANT_YEAR = 2012
export const CALCULATION_MODULE_CATEGORY = 'Calculation module'

export const APP_NAME = environment.appName
export const WIKI_URL = environment.wikiUrl
export const FEEDBACK = environment.feedback
export const API_URL = environment.apiUrl
export const KEYCLOAK_URL = environment.keycloakUrl
export const KEYCLOAK_REALM = environment.keycloakRealm
export const KEYCLOAK_CLIENT_ID = environment.keycloakClientId

//@ToDo constant not used but if L is not there app map component crash (see how Leaflet is extended in map.component)
export const MAP_OPTIONS = {
  zoomControl: false,
  center: L.latLng(environment.centerMap[0], environment.centerMap[1]),
  zoom: environment.zoom,
  minZoom: 4,
  maxZoom: 17,
  zoomAnimationThreshold: 3,
  layers: [],
}

// GeoServer
export const GEOSERVER_URL = environment.geoserverUrl
export const URL_LEGEND =
  GEOSERVER_URL +
  '?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&WIDTH=50&HEIGHT=10&STRICT=false&style='
export const FORMAT_IMAGE = 'image/png8'
export const GEOSERVER_FEATURE_INFO_URL =
  GEOSERVER_URL +
  '?' +
  'SERVICE=WMS&VERSION=1.1.1&REQUEST=GetFeatureInfo&FORMAT=image/png&TRANSPARENT=true&QUERY_LAYERS=' +
  environment.prefixWorkspaceName

//@ToDo: seems not used but can be useful !
// Layer order number
/*
  More the number is big, more hight is the layer on the toolbox
*/
export const INDUSTRY_LAYER_ORDER = 6
//export const SCALE_LAYER_ORDER = 5
export const CM_LAYERS_ORDER = 4
export const LAYERS_ORDER = 3
export const MAPS_ORDER = 1
