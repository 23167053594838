<h2 mat-dialog-title class="dialog-title">Cookies</h2>
<button class="uk-modal-close-default" type="button" uk-close (click)="close()"></button>

<!-- ! EACH TIME YOU CHANGE THIS TEXT, YOU HAVE TO INCREMENT cookiesVersion IN map.component.ts, the message will appear again to all user -->
<div mat-dialog-content>
  The {{ appName }} toolbox uses mandatory operational cookies for identification and access to
  geographical data. By using the toolbox, you agree to the use of these cookies.
</div>

<div mat-dialog-actions align="end">
  <button mat-flat-button color="success" (click)="close()">Close</button>
</div>
