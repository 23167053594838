import { DecimalPipe } from '@angular/common'
import { Component, OnInit, ViewChild } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatDialog, MatDialogModule } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { LayerDeleteConfirmPopupComponent } from '@components/_header-bar/file-management/upload/layer-delete-confirm-popup/layer-delete-confirm-popup.component'
import { BaseClass } from '@core/bases/base.class'
import { UploadedFile } from '@core/models/upload.class'
import { LayersService } from '@core/services/layers.service'
import { UploadService } from '@core/services/upload.service'
import { takeUntil } from 'rxjs'
import { FileManagementComponent } from '../file-management.component'

@Component({
  standalone: true,
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css'],
  imports: [
    DecimalPipe,

    //Forms
    FormsModule,
    ReactiveFormsModule,
    MatDialogModule,

    // Material
    MatTooltipModule,
  ],
})
export class UploadComponent extends BaseClass implements OnInit {
  @ViewChild('inputFile2', { static: true }) inputFile2
  @ViewChild('inputFile', { static: true }) inputFile

  file2Up: File
  isFileOk: boolean = false
  shared: boolean = false
  isUploading: boolean = false // Temporary until api do this async
  uploadedFiles: UploadedFile[] = []

  layers$ = this._layerService.layers$.asReadonly()
  selectedLayer = null

  constructor(
    private _dialog: MatDialog,
    private _uploadService: UploadService,
    private _layerService: LayersService,
    private _fileManagementComponent: FileManagementComponent,
  ) {
    super()
  }

  ngOnInit() {
    // @todo: check if getFiles is useful with getUploadedFiles just after
    this.getFiles()

    this._uploadService.uploadedFiles.pipe(takeUntil(this._unsubscribe$)).subscribe((files) => {
      this.uploadedFiles = files
        .map((file) => {
          if (this._uploadService.filterPersonalOrShareLayerWithTypeInProject(file)) {
            return { ...file, layerName: this._getLayerName(file) }
          }
        })
        .filter((layer) => layer) // to removed undefined,
    })
  }

  private _getLayerName(layer: any): string {
    for (let i in this.layers$())
      if (layer.layer_type == 'heat2')
        return 'Heat density total (future)' //@ToDo: Fix this hardcode workaround for heat2
      else if (this.layers$()[i].workspaceName == layer.layer) return this.layers$()[i].name
    return layer
  }

  getFiles() {
    this._uploadService.list()
    this._uploadService.listShare()
  }

  delete(id: number | UploadedFile) {
    this._uploadService.delete(id)
    this._fileManagementComponent.updateDiskSpace()
  }

  download(upFile: UploadedFile) {
    this._uploadService.download(upFile).then((url) => {
      if (url === '') return
      // window.open(url); //POPUP blocker
      const a = document.createElement('a')
      a.href = url
      a.download = upFile.name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      URL.revokeObjectURL(url)
    })
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length) {
      this.file2Up = event.target.files[0]
      this.isFileOk = true
    } else this.isFileOk = false
  }

  fileUpload() {
    if (!(this.isFileOk && this.selectedLayer)) return

    if (this.file2Up.size / 1000 >= 800) {
      //Ask user to confirm when uploading potential large files.
      if (
        !confirm(
          'Your file may cover a large area, and may take up to some minutes to generate. Would you like to continue anyway?',
        )
      )
        return
    }

    this.isUploading = true
    this._uploadService
      .add(this.file2Up, String(this.shared), this.selectedLayer)
      .then((success) => {
        if (success) {
          this._fileManagementComponent.updateDiskSpace()
          this.file2Up = null
          this.isFileOk = false
          this.selectedLayer = null
          this.inputFile2.nativeElement.value = ''
          this.inputFile.nativeElement.value = ''
        } else this.isFileOk = true
        this.isUploading = false
      })
  }

  openConfirmationDialog(id): void {
    const dialogRef = this._dialog.open(LayerDeleteConfirmPopupComponent, {
      disableClose: true,
      hasBackdrop: true,
      width: '400px',
    })

    dialogRef.afterClosed().subscribe((data) => {
      if (data) this.delete(id)
    })
  }
}
