import { CommonModule } from '@angular/common'
import { Component, Input } from '@angular/core'
import { LayerNamePipe } from '@pipes/layer-name.pipe'
import { NumberFormatPipe } from '@pipes/number-format.pipe'
import { BusinessNamePipe } from './pipes/business-name.pipe'
import { FilterLayersPipe } from './pipes/filter-layers.pipe'

@Component({
  standalone: true,
  selector: '[app-summary-result-container]',
  templateUrl: 'summary-result-container.component.html',
  styleUrls: ['summary-result-container.component.css'],
  imports: [
    // Used for non custom pipes
    CommonModule,

    // Pipes
    LayerNamePipe,
    BusinessNamePipe,
    NumberFormatPipe,
    FilterLayersPipe,
  ],
})
export class SummaryResultContainerComponent {
  @Input('results') results
  @Input('refSelected') refSelected

  isNumber(value) {
    return value.match(/^[.0-9]+$/) != null
  }
}
