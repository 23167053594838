<div class="title-map" [ngClass]="{ 'title-map-dev-mode': isDevOrLocalhost }">
  <div class="menuLeft">
    <div class="toolbox-logo">
      @if (websiteUrl !== '') {
        <a href="{{ websiteUrl }}" target="_blank">
          <img [src]="logoUrl" />
        </a>
      }
      @if (websiteUrl == '') {
        <img [src]="logoUrl" />
      }
      <button
        class="info-icon"
        uk-icon="icon: info;"
        style="position: relative; color: #222"
        (click)="setProjectInfoOpen()"
        matTooltip="Project information"
        matTooltipPosition="below"
      ></button>
      @if (isDevOrLocalhost !== '') {
        <a
          class="dev-mode-title"
          matTooltip="You are on the development version of the platform. Your data may be deleted at any time."
          matTooltipPosition="below"
          >{{ isDevOrLocalhost }} platform
          <span style="position: relative; top: -2px" uk-icon="icon: info;"></span
        ></a>
      }
    </div>
    @if (isLayerTabVisible) {
      <app-file-management></app-file-management>
    }
  </div>
  <app-search-bar></app-search-bar>
  <div class="menuRight">
    @if (wikiUrl !== '') {
      <div class="title-header">
        <a href="{{ wikiUrl }}" target="_blank">
          <span>Wiki</span>
        </a>
      </div>
    }

    <div class="title-header">
      @if (feedback == 'azure') {
        <a (click)="feedBackExpanded = 'expanded'">
          <span>Feedback</span>
        </a>
      } @else if (feedback == '') {
        <a matTooltip="In development" matTooltipPosition="below" class="link-disabled">
          <span>Feedback</span>
        </a>
      } @else {
        <a href="https://ec.europa.eu/eusurvey/runner/CoolLIFEToolbox" target="_blank">Survey</a>
      }
    </div>

    @if (enableLogin) {
      <app-user-management></app-user-management>
    } @else {
      <div class="title-header">
        <a matTooltip="In development" matTooltipPosition="below" class="link-disabled">
          <span>Login</span>
        </a>
      </div>
    }
  </div>
</div>

<app-feedback
  [expandedState]="feedBackExpanded"
  (closeForm)="feedBackExpanded = 'collapsed'"
></app-feedback>

<app-panel-left></app-panel-left>

<div class="container-map">
  <div id="map" [class.click-disable]="isCMRunning"></div>

  <app-right-side-panel
    [hidden]="!openRightSidebar"
    [nutsIds]="nutsIds"
    [layers]="layers"
    [personalLayers]="personalLayers"
    [locationsSelection]="locationsSelection"
    [areas]="areas"
  >
  </app-right-side-panel>
</div>
