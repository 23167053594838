import { Pipe, PipeTransform } from '@angular/core'
import { LayerInteractionService } from '@components/_panel-left/layers-tab/layers-interaction/layers-interaction.service'

@Pipe({
  standalone: true,
  name: 'layerName',
})
export class LayerNamePipe implements PipeTransform {
  constructor(private _layerInteractionService: LayerInteractionService) {}

  transform(value: any): any {
    if (value === null) {
      return 'Nothing to transform'
    }
    const valueTransformed = this._layerInteractionService.getReadableName(value)

    return valueTransformed
  }
}
