import { HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BaseService } from '@bases/base.service'
import { API_URL } from '@core/constants/constant.data'

@Injectable()
export class FeedbackService extends BaseService {
  sendFeedback(data) {
    return super.POSTunStringify(data, API_URL + '/users/feedback', {
      headers: new HttpHeaders(),
    })
  }
}
