import { Injectable, signal } from '@angular/core'
import { BaseService } from '@bases/base.service'
import { GEOSERVER_URL } from '@core/constants/constant.data'
import { INITIAL_SCALE, SCALE_LEVEL_ARRAY } from '@core/constants/scale.data'
import { ScaleLevel } from '@core/models/scale-level.class'
import { environment } from 'environments/environment'
import * as L from 'leaflet'

@Injectable()
export class SelectionScaleService extends BaseService {
  currentScaleLevel$ = signal<ScaleLevel | null>(null)

  setScaleLevelWitDisplayName(displayName: string) {
    let scale = SCALE_LEVEL_ARRAY.find((scale) => scale.displayName == displayName)
    this.currentScaleLevel$.set(scale)
  }

  getSelectionScaleMenu(map: any): L.Control.Layers {
    const SelectionScale: any = {}

    // Getting nuts from the environment
    environment.scaleRequest.forEach((element) => {
      SelectionScale[element.label] = this._getTiLayer(element.option)
    })

    const overlayMaps = {}

    const control = L.control.layers(SelectionScale, overlayMaps, {
      collapsed: false,
      position: 'topright',
      autoZIndex: false, // Prevents map layer to overlap region selection layer.
    })
    control.addTo(map)
    map.scaleControl = control
    map.addLayer(SelectionScale[INITIAL_SCALE]) // # Add this if you want to show, comment this if you want to hide it.-
    return control
  }
  getTooltipMenu() {
    let selectionToolTips: string = ''

    environment.scaleRequest.forEach((element: any) => {
      if (!element.tooltip) return ''

      selectionToolTips += `${element.label}: ${element.tooltip}\n`
    })

    return selectionToolTips
  }

  //@ToDo: check with same method in layer service
  private _getTiLayer(option: any): any {
    const wms_request = L.tileLayer.wms(GEOSERVER_URL, option)
    wms_request.on('load', function () {})
    wms_request.on('tileunload', function () {})
    wms_request.on('tileloadstart', function () {})
    wms_request.on('tileerror', function () {})
    wms_request.on('loading', function () {})

    return wms_request
  }
}
