<div class="button-files">
  @if (isLoggedIn) {
    <a (click)="openFiles()" href="#modal-fileManagement" class="uk-icon-link" uk-toggle>
      <span><span uk-icon="icon: folder"></span>Files</span>
    </a>
  }
</div>

@if (isLoggedIn) {
  <div id="modal-fileManagement" uk-modal>
    <div class="uk-modal-dialog uk-modal-body">
      <button class="uk-modal-close-default" type="button" uk-close></button>
      <h2 class="uk-modal-title">Welcome, {{ firstname }}</h2>

      <div class="uk-child-width-2-2@m uk-grid-match" uk-grid>
        <h3>User disk space ({{ ((diskSpaceUsed / diskSpaceMax) * 100).toFixed(2) }} % used)</h3>
        <div class="chart-disk-space">
          <app-chart
            type="bar"
            [labels]="diskSpaceLabels"
            [options]="diskSpaceOptions"
            [datasets]="diskSpaceDataset"
            [isLoading]="false"
          ></app-chart>
        </div>
      </div>
      @if (localhost) {
        <p id="errorMsgPersonalLayer">
          Personal layers are linked to the connected API at the time of upload. You will not be
          able to load or delete data uploaded locally (when using docker-compose-local.yml) on
          citiwattsdev or vice versa. Send an email citiwatts&#64;hevs.ch to for more information.
        </p>
      }

      <div class="uk-child-width-2-2@m uk-grid-match" uk-grid>
        <app-upload></app-upload>
      </div>
    </div>
  </div>
}
