<div class="buttons-container">
  @for (tab of tabs; track $index) {
    <button
      class="tab-button"
      [class.selected]="panelLeftService.openedTab$() === tab"
      (click)="panelLeftService.openedTab$.set(tab)"
    >
      <p>{{ tab.name }}</p>
    </button>
  }
</div>

@if (panelLeftService.openedTab$()) {
  @switch (panelLeftService.openedTab$().id) {
    @case ('tools') {
      <app-tools-tab (emitClose)="panelLeftService.openedTab$.set(null)"></app-tools-tab>
    }
    @case ('layers') {
      <app-layers-tab (emitClose)="panelLeftService.openedTab$.set(null)"></app-layers-tab>
    }
    @case ('cms') {
      <app-cms-tab (emitClose)="panelLeftService.openedTab$.set(null)"></app-cms-tab>
    }
    @case ('scenario') {
      <app-scenario-tab (emitClose)="panelLeftService.openedTab$.set(null)"></app-scenario-tab>
    }
  }
}
