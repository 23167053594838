<div class="tooltabWrapper">
  <div class="header">
    <h3>Tools</h3>
    <button (click)="emitClose.emit()" uk-icon="close"></button>
  </div>

  <app-selection-tool onclick="event.stopPropagation();"></app-selection-tool>
  @if (isLoggedIn$()) {
    <app-snapshot-panel></app-snapshot-panel>
  }
</div>
