import { animate, state, style, transition, trigger } from '@angular/animations'
import { Component, effect, HostBinding, Input, OnChanges } from '@angular/core'
import { CalculationModuleService } from '@components/_panel-left/cms-tab/calculation-modules/calculation-module.service'
import { SideComponent } from '@components/_panel-right/side-panel/side-panel.component'
import { CONSTANT_YEAR } from '@core/constants/constant.data'
import { Helper } from '@core/services/helper'
import { InteractionService } from '@core/services/interaction.service'
import { SelectionScaleService } from '@core/services/selection-scale.service'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { ScenarioResults } from './result-manager/result-manager'
import { ResultManagerComponent } from './result-manager/result-manager.component'

@Component({
  standalone: true,
  selector: 'app-right-side-panel',
  templateUrl: 'right-side-panel.component.html',
  styleUrls: ['right-side-panel.component.css'],
  animations: [
    trigger('panelWidthTrigger', [
      state('expanded', style({ width: 600 + 'px', display: 'block' })),
      state('collapsed', style({ width: '0px', display: 'none' })),
      transition('collapsed => expanded', animate('200ms ease-in')),
      transition('expanded => collapsed', animate('200ms 200ms ease-out')),
    ]),
    trigger('toggleDrawer', [
      state('collapsed', style({ right: '-600px' })),
      state('expanded', style({ right: '0px' })),
      transition('collapsed <=> expanded', animate('200ms 200ms ease-in')),
    ]),
    trigger('iconTrigger', [
      transition('collapsed => expanded', animate('200ms ease-in')),
      transition('expanded => collapsed', animate('200ms ease-out')),
    ]),
  ],
  imports: [ResultManagerComponent],
})
export class RightSideComponent extends SideComponent implements OnChanges {
  //add animation to host element
  @HostBinding('@toggleDrawer') get getToggleDrawer(): string {
    return this.expanded$() ? 'expanded' : 'collapsed'
  }

  // @ToDo: add type
  @Input() nutsIds
  @Input() layers
  @Input() locationsSelection
  @Input() areas
  @Input() personalLayers

  scaleLevel = this._selectionScaleService.currentScaleLevel$()

  cmPayload
  summaryPayload
  energyMixPayload
  scenarioResultsSelected: ScenarioResults
  personalLayerPayload

  private _currentCM$ = this._calculationModuleService.currentCM$.asReadonly()

  // Components status
  private _electricityMixStatus = false
  private _summaryResultStatus = false

  constructor(
    protected interactionService: InteractionService,
    private _selectionScaleService: SelectionScaleService,
    private _calculationModuleService: CalculationModuleService,
    private _helper: Helper,
  ) {
    super(interactionService)

    effect(() => {
      if (this._currentCM$()) {
        this._setStatusResults()
        this._updateAll()
        this._setCMPayload()
      } else {
        this.cmPayload = null
      }
    })

    effect(() => {
      if (this._selectionScaleService.currentScaleLevel$()) {
        this.scaleLevel = this._selectionScaleService.currentScaleLevel$()
      } else {
      }
    })
  }

  ngOnChanges() {
    if (this.expanded$) {
      this._setStatusResults()
      this._updateAll()
    } else {
      this._resetPayloads()
    }
  }

  private _resetPayloads() {
    this.cmPayload = null
    this.summaryPayload = null
    this.energyMixPayload = null
    this.personalLayerPayload = null
  }

  private _setStatusResults() {
    if (this.scaleLevel.id === 0) {
      this._electricityMixStatus = true
    } else {
      this._electricityMixStatus = false
    }
    this._summaryResultStatus = true
  }

  private _updateAll() {
    if (this._summaryResultStatus && this.scaleLevel.id !== 5) {
      this._setSummaryPayloadIds()
    } else if (this._summaryResultStatus && this.scaleLevel.id === 5) {
      this._setSummaryPayloadArea()
    } else {
      this.summaryPayload = null
    }

    if (this._electricityMixStatus) {
      this._setElectricityMixPayload()
    } else {
      this.energyMixPayload = null
    }
    if (Object.keys(this.personalLayers).length >= 1) {
      this._setPersonalLayerPayload()
    } else {
      this.personalLayerPayload = null
    }
  }

  // Create payloads
  private _setCMPayload() {
    let cm_name = ''
    let areas
    if (this.scaleLevel.id !== 5) {
      areas = this.summaryPayload.nuts
    } else if (this.scaleLevel.id === 5) {
      areas = this.summaryPayload.areas
    }

    if (
      !isNullOrUndefinedString(this._currentCM$().cm.cm_prefix) &&
      this._currentCM$().cm.cm_prefix != ''
    ) {
      cm_name += this._currentCM$().cm.cm_prefix + ' - '
    }
    cm_name += this._currentCM$().cm.cm_name
    this.cmPayload = Object.assign(
      {
        url_file: 0,
        scalevalue: this._selectionScaleService
          .currentScaleLevel$()
          .displayName.replace(' ', '')
          .toLocaleLowerCase(),
        inputs: this._currentCM$().component,
        cm_id: '' + this._currentCM$().cm.cm_id,
        cm_name: cm_name,
        cm_prefix: this._currentCM$().cm.cm_prefix,
      },
      {
        payload: {
          areas: areas,
          year: this.summaryPayload.year,
          layers_needed: this._currentCM$().cm.layers_needed,
          type_layer_needed: this._currentCM$().cm.type_layer_needed,
          vectors_needed: this._currentCM$().cm.vectors_needed,
        },
      },
    )
  }

  private _setPersonalLayerPayload() {
    this.personalLayerPayload = {
      layers: [],
      scale_level: this.scaleLevel.payloadScale,
      areas: this.scaleLevel.id === 5 ? this._helper.getAreasForPayload(this.areas) : this.nutsIds,
    }
    for (let key in this.personalLayers) {
      this.personalLayerPayload['layers'].push(this.personalLayers[key])
    }
  }

  private _setSummaryPayloadIds() {
    const payload = {
      layers: this.layers,
      year: CONSTANT_YEAR,
      scale_level: this._selectionScaleService.currentScaleLevel$().displayName,
      nuts: this.nutsIds,
    }

    this.summaryPayload = payload
  }

  private _setSummaryPayloadArea() {
    const areas = this._helper.getAreasForPayload(this.areas)

    this.summaryPayload = {
      layers: this.layers,
      year: CONSTANT_YEAR,
      scale_level: this._selectionScaleService.currentScaleLevel$().displayName,
      areas: areas,
    }
  }

  private _setElectricityMixPayload() {
    this.energyMixPayload = { nuts: this.nutsIds }
  }
}
