@if (title) {
  <div class="title">
    <h5 class="uk-card-title" style="float: left; margin: 10px">{{ title }}</h5>
  </div>
}

<div class="wrapper-chart">
  <canvas #canvas></canvas>
  @if (isLoading) {
    <div class="spinner" uk-spinner="ratio: 3"></div>
  }
</div>
