import { AfterViewInit, Component, Input, OnChanges, ViewChild } from '@angular/core'
import { Chart, ChartType } from 'chart.js/auto'

@Component({
  standalone: true,
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.css'],
  imports: [],
})
export class ChartComponent implements OnChanges, AfterViewInit {
  @ViewChild('canvas', { static: true }) canvas
  @Input() datasets
  @Input() labels
  @Input() type: ChartType = 'line'
  @Input() title
  @Input() options
  @Input() isLoading = true

  private _chart: Chart<ChartType>

  constructor() {}

  ngOnChanges() {
    this._createChart()
  }

  ngAfterViewInit() {
    this._createChart()
  }

  private _createChart() {
    this._resetChart()
    this._chart = new Chart(this.canvas.nativeElement.getContext('2d'), {
      type: this.type,
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: this.options,
    })
  }

  private _resetChart() {
    if (this._chart) {
      this._chart.destroy()
      this._chart = void 0
    }
  }
}
