<div class="symbolgies">
  @for (symb of symbology; track $index) {
    <div class="symbolgy">
      <div
        class="color-box"
        [style.background-color]="
          'rgba(' + symb.red + ',' + symb.green + ',' + symb.blue + ',' + symb.opacity + ')'
        "
      ></div>
      <div class="label">
        {{ symb.label }}
      </div>
    </div>
  }
</div>
