import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { LayerClass } from '@components/_panel-left/layers-tab/layers-interaction/layers-interaction.class'
import { UploadedLayersService } from '@components/_panel-left/layers-tab/uploaded-layers.service'

import { API_URL, GEOSERVER_URL } from '@core/constants/constant.data'
import { LAYERS_ARRAY } from '@core/constants/layers.data'
import {
  HECTARE,
  LAU2,
  LAU2_GEOSERVER,
  POPULATION,
  SCALE_LEVEL_ARRAY,
} from '@core/constants/scale.data'
import { GeojsonClass } from '@core/models/geojson.class'
import { SelectionScaleService } from '@core/services/selection-scale.service'
import { ToasterService } from '@core/services/toaster.service'
import { UploadService } from '@core/services/upload.service'
import { MapService } from '@pages/map/services/map.service'
import * as L from 'leaflet'
import { map } from 'rxjs/operators'
import { SelectionToolService } from '../selection-tools/service/selection-tool.service'

export interface SnapshotConfig {
  id?: number
  name: string
  description: string
  date: Date

  scale: string
  zones: Array<string | any> // nuts or areas
  layers: string[]

  center: L.LatLng
  zoom: number
}

@Injectable()
export class SnapshotService {
  snapshotUrl: string = API_URL + '/snapshot/'

  constructor(
    private _http: HttpClient,
    private _mapService: MapService,
    private _selectionToolService: SelectionToolService,
    private _selectionScaleService: SelectionScaleService,
    private _toasterService: ToasterService,
    private _uploadService: UploadService,
    private _uploadedLayersService: UploadedLayersService,
  ) {}

  /**
   * Use toaster to show message of success and error
   * @param res Response of the api
   * @param success true from then, false from catch
   */
  private _showMsg(res, success: boolean) {
    this._toasterService.showToaster(res['message'])
    return success
  }

  /**
   * Add a new snapshot
   * @param name
   * @param description
   * @returns Promise with success of the procedure
   */
  add(name: string, description: string = ''): Promise<boolean> {
    const scale = this._selectionScaleService.currentScaleLevel$().displayName

    var selectedLayers = this._mapService.layerArray.getValue()

    var selectedPersonalLayers = []
    this._uploadService.uploadedFiles.subscribe((layers) => {
      selectedPersonalLayers = layers.filter((layer) =>
        this._uploadService.filterPersonalOrShareLayerWithTypeInProject(layer),
      )
    })
    var selectedPersonalLayersId = []
    selectedPersonalLayers.forEach((selectedLayer) => {
      if (selectedLayer.checked) {
        selectedPersonalLayersId.push(selectedLayer.id)
      }
    })

    var selectedShareLayers = []
    this._uploadService.shareFiles.subscribe((layers) => {
      selectedShareLayers = layers.filter((layer) =>
        this._uploadService.filterPersonalOrShareLayerWithTypeInProject(layer),
      )
    })
    var selectedShareLayersId = []
    selectedShareLayers.forEach((selectedLayer) => {
      if (selectedLayer.checked) {
        selectedShareLayersId.push(selectedLayer.id)
      }
    })

    var layers = selectedLayers.concat(selectedPersonalLayersId, selectedShareLayersId)

    let config: SnapshotConfig = {
      name: name,
      description: description,
      date: new Date(),
      scale: scale,
      zones:
        scale !== HECTARE
          ? this._selectionToolService.nutsIdsSubject.getValue()
          : this._selectionToolService.areasSubject.getValue().map((area) => {
              if (area instanceof L.Circle) {
                let circle: any = area as L.Circle
                let radius = circle.getRadius()
                circle = circle.toGeoJSON()
                circle.properties.radius = radius
                return circle
              } else {
                const geoJson: any = (area as L.Polygon).toGeoJSON()
                let features = []
                if ('features' in geoJson) {
                  features = geoJson.features[0]
                } else {
                  features = geoJson
                }
                return features
              }
            }),
      layers: layers,
      center: this._mapService.getMap().getCenter(),
      zoom: this._mapService.zoomLevel.getValue(),
    }

    return this._http
      .post(this.snapshotUrl + 'add', {
        config: JSON.stringify(config),
      })
      .toPromise()
      .then((response) => this._showMsg(response, true))
      .catch((response) => this._showMsg(response, false))
  }

  apply(snapshot: SnapshotConfig, callback?: () => void) {
    const myMap = this._mapService.getMap()

    // remove all
    this._selectionToolService.clearAll()

    // Match layers of snapshot to the current list
    const layers2Toggle: Array<LayerClass> = []
    const uploadedLayer2Toggle: Array<LayerClass> = []
    {
      const lay: Array<string> = snapshot.layers

      for (var i = 0; i < lay.length; i++) {
        LAYERS_ARRAY.forEach((layer) => {
          if (
            layer.category != '' && // not a uploaded layer
            layer.layer_type != 'heat2' && //@ToDo: hard coded
            layer.workspaceName === lay[i]
          ) {
            layers2Toggle.push(layer)
          } else if (
            layer.category == '' && // is a uploaded layer
            layer.id.toString() == lay[i]
          ) {
            uploadedLayer2Toggle.push(layer)
          }
        })
      }
    }

    // Display on map normal layer
    this._mapService.clearLayerSelection()
    layers2Toggle.forEach((layer) => {
      this._mapService.showOrRemoveLayerWithBoolean(layer.workspaceName, layer.order, true)
    })

    // Display on map uploaded layer
    this._uploadedLayersService.unCheckAllUploadedLayers()
    uploadedLayer2Toggle.forEach((layer) => {
      this._uploadedLayersService.personalLayers$().some((personalLayer) => {
        if (personalLayer.id == layer.id && !personalLayer.checked) {
          this._uploadedLayersService.actionLayer('personal', personalLayer)
        }
      })
      this._uploadedLayersService.shareLayers$().some((shareLayer) => {
        if (shareLayer.id == layer.id && !shareLayer.checked) {
          this._uploadedLayersService.actionLayer('shared', shareLayer)
        }
      })
    })

    // Only to check in the list
    LAYERS_ARRAY.forEach((layer) => {
      layer.isSelected = false
      layers2Toggle.some((layer) => {
        if (layer.workspaceName === layer.workspaceName) {
          layer.isSelected = true
        }
      })
      uploadedLayer2Toggle.some((layer) => {
        if (layer.id === layer.id) {
          layer.isSelected = true
        }
      })
    })

    const scaleLevel = SCALE_LEVEL_ARRAY.find((nut) => nut.displayName == snapshot.scale)
    // To change scale
    const control = (myMap as any).scaleControl as L.Control
    control.getContainer().getElementsByTagName('input')[scaleLevel.id].click()

    myMap.flyTo(snapshot.center, snapshot.zoom)

    if (scaleLevel) {
      if (scaleLevel.displayName != HECTARE) {
        // Working but a little slow
        const isLau2: boolean = scaleLevel.displayName == LAU2
        const nameId = isLau2 ? 'comm_id' : 'nuts_id'
        const layer = isLau2 ? LAU2_GEOSERVER : POPULATION
        const date_filter = isLau2 ? '' : "date='2013-01-01' AND "
        const stat_level_filter = isLau2 ? '' : ' AND stat_levl_=' + scaleLevel.id

        let nuts_ids = `${nameId}='${snapshot.zones.join(`' OR ${nameId}='`)}'`

        let url =
          GEOSERVER_URL +
          '?service=WFS&version=2.0.0&request=GetFeature' +
          `&typeNames=hotmaps:${layer}&outputFormat=application/json` +
          `&cql_filter=${date_filter}(${nuts_ids})${stat_level_filter}`

        this._http
          .get(url)
          .pipe(map((res) => res as GeojsonClass))
          .subscribe(
            (res) => {
              res.features.forEach((geo) => this._mapService.selectAreaWithNuts(geo))
              if (callback) callback()
            },
            (err) => {
              console.warn('Failed get snapshot from geoserver', err)
              if (callback) callback()
            },
          )
      } else {
        snapshot.zones.forEach((zone) => {
          let shape: any
          if (zone.properties && zone.properties.radius) {
            shape = L.geoJSON(zone as any, {
              pointToLayer: (feature: any, latlng: L.LatLng) => {
                return new L.Circle(latlng, feature.properties.radius)
              },
            })
            shape.radius = zone.properties.radius
            shape.latLng = L.GeoJSON.coordsToLatLng(zone.geometry.coordinates)
          } else {
            shape = L.polygon(L.GeoJSON.coordsToLatLngs(zone.geometry.coordinates[0]))
          }
          this._selectionToolService.drawHectaresLoadingResult(shape)
        })
        if (callback) callback()
      }
    }
  }

  /**
   * Get the list of the snapshots
   * @returns Promise with the snapshots
   */
  async list(): Promise<SnapshotConfig[]> {
    const response = await this._http.post(this.snapshotUrl + 'list', {}).toPromise()
    const snaps: Array<any> = response['snapshots']
    const snapshots: SnapshotConfig[] = []
    for (var i in snaps) {
      const snap: SnapshotConfig = JSON.parse(snaps[i]['config'])
      snap.id = snaps[i]['id']
      snapshots.push(snap)
    }
    return snapshots
  }

  /**
   * Delete a snapshot
   * @param id id of the snapshot to delete
   * @returns Promise with success of the procedure
   */
  delete(id: number | SnapshotConfig): Promise<boolean> {
    if (typeof id !== 'number') id = (id as SnapshotConfig).id

    let jsonparams = {
      id: id,
    }

    return this._http
      .delete(this.snapshotUrl + 'delete', { body: jsonparams })
      .toPromise()
      .then((response) => this._showMsg(response, true))
      .catch((response) => this._showMsg(response, false))
  }
}
