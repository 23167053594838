import { Pipe, PipeTransform } from '@angular/core'
import { BusinessNameService } from '@core/services/business.service'

@Pipe({
  standalone: true,
  name: 'businessName',
})
export class BusinessNamePipe implements PipeTransform {
  constructor(private _businessService: BusinessNameService) {}

  transform(value: any): string {
    if (value === null) {
      return 'Nothing to transform'
    }

    // @ToDo: See if reference change needed
    value = this._businessService.getReadableName(value)
    return value
  }
}
