import { Component, signal } from '@angular/core'
import { InteractionService } from '@core/services/interaction.service'

@Component({
  template: '',
})
export class SideComponent {
  expanded$ = signal<boolean>(false)
  expandedState$ = signal<string>('collapsed')
  protected title = 'SideComponent title'

  constructor(protected interactionService: InteractionService) {}

  setTitle(title: string) {
    this.title = title
  }
  getTitle(): string {
    return this.title
  }

  toggleExpandedState() {
    this.expandedState$.set(this.expanded$() ? 'collapsed' : 'expanded')
    this.expanded$.set(!this.expanded$)
  }

  displayPanel(val: boolean) {
    if (val) {
      this.expandedState$.set('expanded')
      this.expanded$.set(true)
    } else {
      this.expandedState$.set('collapsed')
      this.expanded$.set(false)
    }
  }
}
