<ul uk-accordion>
  <li class="uk-open">
    <h3 class="uk-accordion-title">Personal layer</h3>
    <div class="uk-accordion-content" style="position: relative; width: 100%">
      @for (layer of uploadedLayersService.personalLayers$(); track $index) {
        <div class="layers" (click)="uploadedLayersService.actionLayer('personal', layer)">
          @if (layer.shared == 'true') {
            <button
              type="button"
              class="uk-button uk-button-default uk-width-1-1"
              [class.button-secondary]="layer.checked"
            >
              <input class="uk-checkbox" type="checkbox" [checked]="layer.checked" /><span
                >[S] {{ layer.name }}</span
              >
            </button>
          } @else {
            <button
              type="button"
              class="uk-button uk-button-default uk-width-1-1"
              [class.button-secondary]="layer.checked"
            >
              <input class="uk-checkbox" type="checkbox" [checked]="layer.checked" /><span
                >[P] {{ layer.name }}</span
              >
            </button>
          }
        </div>
      }
    </div>
  </li>
</ul>
