import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class SidePanelService {
  //@ToDo change in signal

  public rightPanelStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  openRightPanel() {
    this.rightPanelStatus.next(true)
  }
  closeRightPanel() {
    this.rightPanelStatus.next(false)
  }
}
