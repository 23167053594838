import { CommonModule } from '@angular/common'
import { Component, OnInit } from '@angular/core'
import { FEEDBACK } from '@core/constants/constant.data'
import { MapService } from '@pages/map/services/map.service'
import { environment } from 'environments/environment'

@Component({
  standalone: true,
  selector: 'htm-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss'],
  imports: [CommonModule],
})
export class WelcomePageComponent implements OnInit {
  feedback = FEEDBACK
  appName = environment.appName
  logoUrl = environment.logoUrl
  partnersLogosUrl = environment.partnersLogosUrl
  introText = environment.introText
  feedbackText = environment.feedbackText
  websiteUrl = environment.websiteUrl
  wikiUrl = environment.wikiUrl
  repositoryUrl = environment.repositoryUrl
  dataSetURl = environment.dataSetURl
  fundingText = environment.fundingText
  fundingImg = environment.fundingImg
  isDevOrLocalhost = ''

  constructor(private _mapService: MapService) {}

  ngOnInit() {
    this.isDevOrLocalhost = this._mapService.setIsDevOrLocalHost()
  }

  setProjectInfoClose() {
    this._mapService.setProjectInfoClose()
  }
}
