import { WIKI_URL } from '@core/constants/constant.data'

export const STANDALONE_CMS = [
  {
    name: 'CM - CUSTOMIZED HEAT AND GROSS FLOOR AREA DENSITY MAPS',
    wiki_url: WIKI_URL + '/en/CM-Customized-heat-and-floor-area-density-maps',
    repository_url: 'https://github.com/HotMaps/customized_h_fa_dm',
    project: ['citiwatts'],
  },
  {
    name: 'CM - ADD INDUSTRY PLANT',
    wiki_url: WIKI_URL + '/en/CM-Add-industry-plant',
    repository_url: 'https://github.com/HotMaps/add_industry_plant_cm',
    project: ['citiwatts'],
  },
  {
    name: 'CM - DISTRICT HEATING SUPPLY DISPATCH - EXTENDED',
    wiki_url: 'https://hotmapsdispatch.readthedocs.io/en/latest',
    repository_url: 'https://github.com/tuw-eeg/hotmapsDispatch',
    project: ['citiwatts'],
  },
  {
    name: 'CM - SCENARIO ASSESSMENT',
    wiki_url: WIKI_URL + '/en/CM-Scenario-assessment',
    repository_url: 'https://github.com/HotMaps/scenario_assessment',
    project: ['citiwatts'],
  },
]
