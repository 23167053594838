//@ToDo list not corresponding to reel layer category

import { CALCULATION_MODULE_CATEGORY } from '@core/constants/constant.data'

export const DEFAULT_DROP_DOWN_BUTTON = 'overall'
export const DROP_DOWN_RESULT_LIST = [
  {
    name: 'Results overview',
    ref: DEFAULT_DROP_DOWN_BUTTON,
    selected: true,
    display: false,
  },
  { name: 'Demand', ref: 'demand', selected: false, display: false },
  { name: 'Potentials', ref: 'potential', selected: false, display: false },
  { name: 'Climate', ref: 'climate', selected: false, display: false },
  { name: 'Buildings', ref: 'buildings', selected: false, display: false },
  { name: 'Heat Supply', ref: 'heat_supply', selected: false, display: false },
  { name: 'Industry', ref: 'industry', selected: false, display: false },
  { name: 'Electricity', ref: 'electricity', selected: false, display: false },
  { name: 'Population', ref: 'population', selected: false, display: false },
  { name: 'Mobility', ref: 'mobility', selected: false, display: false },
  {
    name: CALCULATION_MODULE_CATEGORY,
    ref: CALCULATION_MODULE_CATEGORY,
    selected: false,
    display: false,
  },
]
