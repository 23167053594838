import { Injectable } from '@angular/core'
import { BaseService } from '@bases/base.service'
import { API_URL } from '@core/constants/constant.data'

@Injectable()
export class ElectricityMixService extends BaseService {
  getElectricityMix(payload) {
    return super.POST(payload, API_URL + '/stats/energy-mix/nuts-lau')
  }
}
