import { NgClass } from '@angular/common'
import { Component, EventEmitter, Input, OnInit, Output, Signal, ViewChild } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { CALCULATION_MODULE_CATEGORY, LAYERS_ORDER } from '@core/constants/constant.data'
import { categoryUsed } from '@core/models/enum'
import { AuthService } from '@core/services/auth.service'
import { InteractionService } from '@core/services/interaction.service'
import { LayersService } from '@core/services/layers.service'
import { UploadService } from '@core/services/upload.service'
import { MapComponent } from '@pages/map/map.component'
import { MapService } from '@pages/map/services/map.service'
import { LayerCellComponent } from './layers-interaction/layers-interaction-cell/layers-interaction-cell.component'
import { LayerClass } from './layers-interaction/layers-interaction.class'
import { PersonalLayersComponent } from './personal-layers/personal-layers.component'
import { FilterCatWithNamePipe } from './pipes/filter-cat-with-name.pipe'
import { filterLayerOnCategoryPipe } from './pipes/filter-layer-on-category.pipe'
import { ShareLayersComponent } from './share-layers/share-layers.component'
import { UploadedLayersService } from './uploaded-layers.service'

@Component({
  standalone: true,
  selector: 'app-layers-tab',
  templateUrl: './layers-tab.component.html',
  styleUrls: ['./layers-tab.component.css'],
  imports: [
    NgClass,

    // Form
    FormsModule,
    ReactiveFormsModule,

    // Components
    ShareLayersComponent,
    PersonalLayersComponent,
    LayerCellComponent,

    // Pipes
    FilterCatWithNamePipe,
    filterLayerOnCategoryPipe,
  ],
})
export class LayersTabComponent implements OnInit {
  @Input() layersOpened //@ToDo : why empty ?
  @Output() emitClose = new EventEmitter<void>()

  @ViewChild(PersonalLayersComponent, { static: false })
  personalLayersComponent: PersonalLayersComponent
  @ViewChild(ShareLayersComponent, { static: false })
  shareLayersComponent: ShareLayersComponent

  isConnected = false
  //category: layerCategory[] = []
  expanded = true

  search = ''
  search$ = this.layersService.search$
  layers$: Signal<LayerClass[]> = this.layersService.layers$
  filteredLayers$: Signal<LayerClass[]> = this.layersService.filteredLayers$

  constructor(
    public mapComponent: MapComponent,
    protected mapService: MapService,
    private authService: AuthService,
    public layersService: LayersService,
    private _uploadService: UploadService,
    public interactionService: InteractionService,
    public uploadedLayersService: UploadedLayersService,
  ) {}

  async ngOnInit() {
    this.isConnected = await this.authService.isLoggedIn()
    this._getLayerAndCategory(this.layers$())
  }

  private _getLayerAndCategory(layers: LayerClass[]) {
    // @ToDo: refactor
    this.layersService.category$.set(
      layers
        .map((item) => {
          return { name: item.category, used: 0 }
        })
        .filter((value, index, self) => index === self.findIndex((c) => c.name === value.name)),
    )
  }

  checkGroup(categoryName: string) {
    for (let c = 0; c < this.layersService.category$().length; c++) {
      if (categoryName == this.layersService.category$()[c].name) {
        if (this.layersService.category$()[c].used == categoryUsed.UNSELECTED) {
          this.layersService.category$()[c].used = categoryUsed.ALL_SELECTED
          this._selectLayers(this.layersService.category$()[c].name, true)
        } else {
          this.layersService.category$()[c].used = categoryUsed.UNSELECTED
          this._selectLayers(this.layersService.category$()[c].name, false)
        }
        break
      }
    }
  }

  unselectAllLayers() {
    this.uploadedLayersService.unCheckAllUploadedLayers()

    this._unCheckAllLayers()
    this.mapService.clearLayerSelection()
    let uploadedLayers = this.layers$().filter((layer) => layer.id != 0)
    uploadedLayers.forEach((layer) => {
      this._uploadService.remove(layer.id)
    })
  }

  isGroupChecked(categoryName: string, currentLayers: LayerClass[]): boolean {
    const categoryLayers = currentLayers.filter((layer) => layer.category === categoryName)

    if (categoryLayers.length === 0) return false

    for (const layer of categoryLayers) {
      if (layer.category !== categoryName || !layer.isSelected) return false
    }

    return true
  }

  private _unCheckAllLayers() {
    this.layers$().forEach((layer) => {
      layer.isSelected = false
      layer.isLoading = false
    })
    this.layersService.category$().forEach((category) => {
      category.used = categoryUsed.UNSELECTED
    })
  }

  private _selectLayers(category: string, boolean: boolean) {
    for (let layer = 0; layer < this.layers$().length; layer++) {
      if (this.layers$()[layer].category == category) {
        if (this.interactionService.summaryResultState === false) {
          // @ToDo: cannot change isSelected like that
          this.layers$()[layer].isSelected = boolean

          if (this.layers$()[layer].category === CALCULATION_MODULE_CATEGORY) {
            this.mapService.displayCustomLayerFromCM(
              this.layers$()[layer].cm_id,
              this.layers$()[layer].type_of_layer,
            )
          } else {
            let order = LAYERS_ORDER
            if (this.layers$()[layer].order) {
              order = this.layers$()[layer].order
            }
            this.mapService.showOrRemoveLayerWithBoolean(
              this.layers$()[layer].workspaceName,
              order,
              boolean,
            )
            this.mapService.setLayersSubject()
          }
        }
      }
    }
  }
}
