/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 19.06.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/
import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Injectable()
export class LoaderService {
  //@ToDo change in signal
  private _status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

  display(value: boolean) {
    this._status.next(value)
  }

  getStatus(): BehaviorSubject<boolean> {
    return this._status
  }
}
