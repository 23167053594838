<span>
  <div class="uk-card uk-card-body htm-card">
    <table class="uk-table uk-table-divider uk-table-small">
      <thead>
        <tr class="header-summary">
          <th class="uk-table-shrink">Information</th>
          <th class="uk-table-shrink">Value</th>
        </tr>
      </thead>
      @if (summaryResult.summaryResult) {
        <tbody
          app-summary-result-container
          [results]="summaryResult.summaryResult"
          [refSelected]="refSelected"
        ></tbody>
      }
      @if (summaryResult.cmResult) {
        <tbody
          app-summary-result-container
          [results]="summaryResult.cmResult"
          [refSelected]="refSelected"
        ></tbody>
      }
      @if (summaryResult.personalLayerResult) {
        <tbody
          app-summary-result-container
          [results]="summaryResult.personalLayerResult"
          [refSelected]="refSelected"
        ></tbody>
      }

      @if (summaryResult.scenarioResult) {
        @if (summaryResult.scenarioResult.scenarioParameters.region == scale) {
          <tbody
            app-summary-result-container
            [results]="summaryResult.scenarioResult"
            [refSelected]="refSelected"
          ></tbody>
        } @else {
          <tbody>
            There is no data for the selected region. Please ensure that the region scale is the
            same as the one in the scenario panel.
          </tbody>
        }
      }
    </table>
  </div>
</span>
