import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core'
import { ImportCmInputsService } from './import-cm-inputs.service'

@Component({
  selector: 'app-import-cm-inputs',
  standalone: true,
  imports: [],
  templateUrl: './import-cm-inputs.component.html',
  styleUrls: ['./import-cm-inputs.component.css'],
  providers: [ImportCmInputsService],
})
export class ImportCmInputsComponent {
  @Output() dataImported = new EventEmitter<any>()
  @ViewChild('csvFileInput') csvFileInput: ElementRef

  csvFile: File | undefined

  constructor(private _importCmInputsService: ImportCmInputsService) {}

  handleFileInput(event: any) {
    const files = event.target.files
    if (files.length > 0) {
      this.csvFile = files[0]
    }
  }

  importInputs() {
    if (!this.csvFile) {
      console.warn('No CSV file selected.')
      return
    }

    const csvUrl = URL.createObjectURL(this.csvFile)
    this._importCmInputsService
      .importData(csvUrl)
      .then((dataArray) => {
        this.dataImported.emit(dataArray)
        this.csvFileInput.nativeElement.value = null
      })
      .catch((error) => {
        console.warn('ImportDataComponent/importIndicators/error', error)
      })
  }
}
