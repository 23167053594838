import { Injectable } from '@angular/core'
import { GeojsonClass } from '@core/models/geojson.class'
import { Helper } from '@core/services/helper'

@Injectable()
export class SelectionToolUtilsService {
  constructor(private _helper: Helper) {}

  getSelectionIdFromLayer(layer): any {
    const geojsonLayer: any = <any>layer
    const geoJson: GeojsonClass = geojsonLayer.toGeoJSON()
    const feature = geoJson.features[0]

    let id_selection = this._helper.getSelectionIDFromGeoJsonLayer(feature)
    return id_selection
  }

  layersAsLayer(layers): boolean {
    let hasLayer = false
    if (layers.getLayers().length > 0) {
      hasLayer = true
    }
    return hasLayer
  }
}
