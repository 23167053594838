import { Injectable, signal } from '@angular/core'
import { Tab } from './tab.class'

@Injectable({
  providedIn: 'root',
})
export class PanelLeftService {
  openedTab$ = signal<Tab>(null)

  constructor() {}
}
