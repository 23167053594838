import { CommonModule } from '@angular/common'
import { AfterContentInit, Component, effect, OnDestroy, OnInit, ViewChild } from '@angular/core'
import { FormsModule } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatTooltipModule } from '@angular/material/tooltip'
import { RouterModule } from '@angular/router'
import { FeedbackComponent } from '@components/_header-bar/feedback/feedback.component'
import { feedbackExpendedState } from '@components/_header-bar/feedback/feedback.interface'
import { FileManagementComponent } from '@components/_header-bar/file-management/file-management.component'
import { SearchBarComponent } from '@components/_header-bar/searchbar/searchbar.component'
import { UserManagementComponent } from '@components/_header-bar/user-management/user-management.component'
import { CalculationModuleService } from '@components/_panel-left/cms-tab/calculation-modules/calculation-module.service'
import { ImportCmInputsService } from '@components/_panel-left/cms-tab/calculation-modules/import-cm-inputs/import-cm-inputs.service'
import { LayerInteractionService } from '@components/_panel-left/layers-tab/layers-interaction/layers-interaction.service'
import { UploadedLayersService } from '@components/_panel-left/layers-tab/uploaded-layers.service'
import { SelectionToolService } from '@components/_panel-left/tools-tab/selection-tools/service/selection-tool.service'
import { SnapshotService } from '@components/_panel-left/tools-tab/snapshot/snapshot.service'
import { ElectricityMixService } from '@components/_panel-right/results-tab/result-manager/electricity-mix/electricity-mix.service'
import { ScenarioResults } from '@components/_panel-right/results-tab/result-manager/result-manager'
import { SummaryResultService } from '@components/_panel-right/results-tab/result-manager/summary-result/summary-result.service'
import { RightSideComponent } from '@components/_panel-right/results-tab/right-side-panel.component'
import { SidePanelService } from '@components/_panel-right/side-panel/side-panel.service'
import { CookiesComponent } from '@components/cookies/cookies.component'
import { WIKI_URL } from '@core/constants/constant.data'
import { LAYERS_ARRAY } from '@core/constants/layers.data'
import { INITIAL_SCALE } from '@core/constants/scale.data'
import { Location } from '@core/models/location/location'
import { SelectionScaleService } from '@core/services/selection-scale.service'
import { UploadService } from '@core/services/upload.service'
import { environment } from 'environments/environment'
import { DrawMap, Layer } from 'leaflet'
import 'leaflet-draw'
import { RecaptchaLoaderService } from 'ng-recaptcha'
import { PanelLeftComponent } from '../../components/_panel-left/panel-left.component'
import { MapService } from './services/map.service'
declare const L: any

@Component({
  standalone: true,
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.less'],
  imports: [
    FeedbackComponent,
    UserManagementComponent,
    CommonModule,
    FormsModule,
    RouterModule,
    SearchBarComponent,
    RightSideComponent,
    FileManagementComponent,
    MatTooltipModule,
    PanelLeftComponent,
  ],
  providers: [
    UploadService,
    LayerInteractionService,
    SummaryResultService,
    ImportCmInputsService,
    ElectricityMixService,
    SnapshotService,
    RecaptchaLoaderService,
    UploadedLayersService,
  ],
})
export class MapComponent implements OnInit, AfterContentInit, OnDestroy {
  isLayerTabVisible = false
  isCMTabVisible = false
  isScenarioTabVisible = false

  websiteUrl = environment.websiteUrl
  wikiUrl = WIKI_URL
  feedback = environment.feedback
  appName = environment.appName
  logoUrl = environment.logoUrl
  enableLogin = environment.enableLogin

  nutsIds: string[]
  locationsSelection: Location[]
  areas: Layer[]
  layers // @ToDo check if conflict with the import of layers
  scaleLevel
  personalLayers
  isCMRunning = false

  feedBackExpanded: feedbackExpendedState = 'collapsed'

  // management of initial status of sidebar
  openRightSidebar = false
  openRightToggleExpanded = false
  // declaration of the left and right sidebar
  isDevOrLocalhost = ''
  scenarioResults: ScenarioResults
  cookiesVersion = '2'

  @ViewChild(SearchBarComponent, { static: true })
  searchBarComponent: SearchBarComponent

  @ViewChild(RightSideComponent, { static: true })
  private _rightPanelComponent: RightSideComponent

  private _map: DrawMap

  constructor(
    private _mapService: MapService,
    private _panelService: SidePanelService,
    private _uploadService: UploadService,
    private _selectionToolService: SelectionToolService,
    private _selectionScaleService: SelectionScaleService,
    public sidePanelService: SidePanelService,
    private _calculationModuleService: CalculationModuleService,
    private _dialog: MatDialog,
  ) {
    this.isDevOrLocalhost = this._mapService.setIsDevOrLocalHost()

    if (this.cookiesVersion != localStorage.getItem('cookiesAccepted')) {
      this._dialog.open(CookiesComponent, {
        data: { cookiesVersion: this.cookiesVersion },
        hasBackdrop: false,
        disableClose: false,
        position: { top: '60px' },
      })
    }

    this._selectionScaleService.setScaleLevelWitDisplayName(INITIAL_SCALE)

    effect(() => {
      if (this._selectionScaleService.currentScaleLevel$()) {
        this.scaleLevel = this._selectionScaleService.currentScaleLevel$()
        this._mapService.setLayersSubject()
      }
      if (this._selectionToolService.selectedSurface$() === 0) {
        this.openRightSidebar = false
      }
    })
  }

  ngOnInit() {
    // mapService get an instance of the maps and can work on it
    this._mapService.setupMapService(this._createMap())

    this._map.invalidateSize()

    if (LAYERS_ARRAY.filter((layer) => layer.projects?.includes(environment.appName)).length > 0) {
      this.isLayerTabVisible = true
    }
    if (environment.cmsId.length > 0) {
      this.isCMTabVisible = true
    }

    if (environment.scenarioExplorer !== '') {
      this.isScenarioTabVisible = true
    }
  }

  ngAfterContentInit(): void {
    this.notifySubscription()
    this._rightPanelComponent.setTitle('Results')
  }

  ngAfterViewChecked(): void {
    window.dispatchEvent(new Event('resize'))
  }

  ngOnDestroy() {
    this._map.remove()
  }

  notifySubscription() {
    // Update personal layers
    if (this._uploadService.activePersonalLayers) {
      this._uploadService.activePersonalLayers.subscribe((lay) => {
        this.personalLayers = Object.assign({}, lay)
      })
    }

    this._calculationModuleService.cmRunning.subscribe((value) => {
      this.isCMRunning = value
      if (value) {
        this.sidePanelService.openRightPanel()
      }

      this._mapService.cmRunning = value
    })

    // Update Layer
    if (this._mapService.layerArray !== null) {
      this._mapService.layerArray.subscribe((data) => {
        this.layers = data
      })
    }
    this._selectionToolService.areasSubject.subscribe((areas) => {
      this.areas = areas
    })
    this._selectionToolService.nutsIdsSubject.subscribe((data) => {
      this.nutsIds = data

      if (this.scenarioResults) {
        var scenarioDataSelected = []
        var scenarioNoDataSelected = []
        this.nutsIds.forEach((id) => {
          var layerIdWithData = this.scenarioResults.data.find(
            (feature) => feature.properties.gid == id || feature.properties.gid_0 == id,
            // David Braz Jorge: plan4res 1.0 model has a different key for GID ?
          )
          if (layerIdWithData) {
            scenarioDataSelected.push(layerIdWithData)
          } else {
            scenarioNoDataSelected.push(id)
          }
        })

        this._rightPanelComponent.scenarioResultsSelected = {
          data: [],
          layers: scenarioDataSelected,
          no_data_layers: scenarioNoDataSelected,
          scenarioParameters: this.scenarioResults.scenarioParameters,
        }
      }
    })

    this._selectionToolService.locationsSubject.subscribe((data) => {
      this.locationsSelection = data
    })

    // //@ToDo: refactor how to open an close right panel
    this._panelService.rightPanelStatus.subscribe((val: boolean) => {
      if (this._selectionToolService.selectedSurface$() <= 0) {
        this.openRightSidebar = false
        this._rightPanelComponent.displayPanel(false)
        return
      }
      this.openRightSidebar = val
      this._rightPanelComponent.displayPanel(val)
    })
  }

  setProjectInfoOpen() {
    this._mapService.setProjectInfoOpen()
  }

  // main method create and display map (main purpose of this component)
  private _createMap(): DrawMap {
    //setup the map from leaflet
    this._map = L.map('map', {
      zoomControl: false,
      center: L.latLng(environment.centerMap[0], environment.centerMap[1]),
      zoom: environment.zoom,
      minZoom: 4,
      maxZoom: 17,
      zoomAnimationThreshold: 3,
      layers: [],
    })

    if (environment.fundingText !== '' || environment.fundingImg !== '') {
      var eu_logo = L.control({ position: 'bottomright', onAdd: () => {} })

      eu_logo.onAdd = function () {
        var div = L.DomUtil.create('div', 'eu_logo')
        div.style.background = 'rgba(255, 255, 255, 0.8)'
        div.style.padding = '5px'
        div.style.maxWidth = '500px'

        div.style.display = 'flex'
        div.style.alignItems = 'center'

        var logoContainer = document.createElement('div')
        var img = document.createElement('img')
        var textContainer = document.createElement('div')
        var textSpan = document.createElement('span')

        logoContainer.style.display = 'flex'
        logoContainer.style.height = 70 + 'px'
        logoContainer.style.justifyContent = 'center'
        logoContainer.style.marginRight = '10px'

        // image styling
        img.src = environment.fundingImg
        img.style.height = '100%'
        img.style.maxWidth = '100%'

        if (environment.fundingText !== '') {
          // text container styling
          textContainer.style.width = '300px'
          textContainer.style.marginRight = '10px'
          textContainer.style.textAlign = 'justify'
          textContainer.style.lineHeight = '1.2'

          // text span styling
          textSpan.style.fontSize = '10px'
          textSpan.textContent = environment.fundingText
        }

        // append elements to have image first
        logoContainer.appendChild(img)
        div.appendChild(logoContainer)
        textContainer.appendChild(textSpan)
        div.appendChild(textContainer)

        return div
      }
      this._map.addControl(eu_logo)
    }

    L.Map = L.Map.extend({
      openPopup: function (popup) {
        this.closePopup()
        this._popup = popup
        this._logger.log('MapComponent/popup ', popup)
        return this.addLayer(popup).fire('popupopen', {
          popup: this._popup,
        })
      },
    })

    L.Control = L.Control.extend({
      delete: function (popup) {
        this._popup = popup
        return this.addLayer(popup).fire('popupDelete', {
          popup: this._popup,
        })
      },
    })
    L.control.scale().addTo(this._map)

    L.control.zoom({ position: 'bottomright' }).addTo(this._map)

    return this._map
  }
}
