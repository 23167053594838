import { Injectable, signal } from '@angular/core'
import { BaseService } from '@bases/base.service'
import { API_URL } from '@core/constants/constant.data'
import { environment } from 'environments/environment'
import { BehaviorSubject } from 'rxjs'
import { CalculationModuleClass } from './service/calculation-module.class'

@Injectable()
export class CalculationModuleService extends BaseService {
  categories = []

  currentCM$ = signal<any>(null) //@ToDo: define a type for CM

  //@ToDo change in signal
  waitingCM$ = signal<boolean>(true)
  panelIsOpen: BehaviorSubject<any> = new BehaviorSubject<any>(false)
  cmAnimationStatus: BehaviorSubject<any> = new BehaviorSubject<any>(0)
  cmRunning: BehaviorSubject<any> = new BehaviorSubject<any>(false)

  getCalculationModuleServicesPromise(): Promise<CalculationModuleClass[]> {
    return Promise.resolve(this._getCalculationModuleServices()).then((cmsArray) =>
      cmsArray.filter((cm) => environment.cmsId.includes(cm.cm_id)),
    )
  }

  private _getCalculationModuleServices(): Promise<any> {
    return super.POST('', API_URL + '/cm/list')
  }

  getCalculationModuleComponents(cmId): Promise<any> {
    const payload = { cm_id: '' + cmId }
    return super.POST(payload, API_URL + '/cm/user-interface/')
  }

  getCalculationModuleCategories(cms): string[] {
    return [...new Set(cms.map((cm) => cm.category))] as string[]
  }

  getCMInformation(payload) {
    return super.POST(payload, API_URL + '/cm/compute-async/')
  }

  getStatusOfCM(status_id) {
    return super
      .GET(API_URL + '/cm/status/' + status_id)
      .toPromise()
      .then((response) => response)
      .catch(this.handleError.bind(this))
  }

  deleteCM(id) {
    return super.DELETE(API_URL + '/cm/delete/' + id)
  }
}
