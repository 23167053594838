@if (calculationModules$()?.length === 0) {
  <span class="no-data"
    >No calculation module runs. <br />
    Please, try later!</span
  >
}

@if (calculationModules$()?.length >= 1) {
  @if (calculationModuleService.waitingCM$()) {
    <div class="waitingbox">
      <div class="spinner" uk-spinner></div>
    </div>
  }

  <!-- List of CM -->
  <div
    [ngClass]="{
      redisplay: !calculationModuleService.panelIsOpen.value,
      undisplay: calculationModuleService.panelIsOpen.value,
    }"
    class="uk-margin"
  >
    <input
      type="text"
      class="uk-input search"
      placeholder="Search Calculation Modules..."
      [ngModel]="searchCM"
      (ngModelChange)="searchCMs($event)"
    />
    @for (category of categories$(); track $index) {
      <ul uk-accordion>
        <li class="uk-open">
          <h3 class="uk-accordion-title">
            {{ category }}
          </h3>
          <div class="uk-accordion-content">
            @for (cm of displayedCalculationModules$(); track $index) {
              @if (category === cm.category) {
                <div class="uk-container">
                  <div class="uk-offcanvas-content">
                    <button
                      class="uk-button uk-button-default uk-margin-small-right"
                      [class.button-disabled]="
                        selectionToolService.selectedSurface$() >= maxSurfaceValueCM ||
                        selectionToolService.selectedSurface$() <= 0 ||
                        !validateAuthorizedScale(cm)
                      "
                      [class.api-inprogress]="cm.isApiRequestInTreatment"
                      type="button"
                      [disabled]="
                        selectionToolService.selectedSurface$() >= maxSurfaceValueCM ||
                        selectionToolService.selectedSurface$() <= 0 ||
                        !validateAuthorizedScale(cm)
                      "
                      (click)="selectCM(cm)"
                    >
                      @if (cm.isApiRequestInTreatment) {
                        Refresh
                      }
                      {{ cm.cm_name }}
                    </button>
                  </div>
                </div>
              }
            }
          </div>
        </li>
      </ul>
    }
    <app-stand-alone-cms></app-stand-alone-cms>
  </div>

  <!-- CM open -->
  <div [ngClass]="{ appear: cmSelected, disappear: !cmSelected }">
    @if (cmSelected) {
      <div style="display: flex; align-items: flex-start">
        <a class="button" type="button" (click)="toggleCMPanel(false)"
          ><span style="width: 25px" uk-icon="icon: arrow-left; ratio: 2"></span
        ></a>
        <div style="margin-right: 30px">
          <h4 class="cm_title">{{ cmSelected.cm_name }}</h4>

          <span class="uk-margin input-prefix">
            Session name
            <input
              class="uk-input"
              maxlength="50"
              type="text"
              placeholder="{{ nowStr }}"
              [(ngModel)]="prefix_cm"
            /> </span
          ><br />
          <span class="uk-margin description">{{ cmSelected.cm_description }}</span
          ><br />
          @if (cmSelected.wiki_url) {
            <span class="uk-margin description-link">
              <a class="uk-link-heading" href="{{ cmSelected.wiki_url }}" target="_blank">
                <div src="/assets/icons/logo_wiki.svg" class="icon-wiki"></div
              ></a>
            </span>
            <br />
          }

          @if (cmSelected.status_id) {
            <span class="uk-margin"> id of request: {{ cmSelected.status_id }}</span>
          }

          @if (calculationModuleService.waitingCM$()) {
            <div class="waitingboxcomponents">
              <div class="spinner" uk-spinner></div>
            </div>
          }

          <div style="margin-top: 10px">
            <app-import-cm-inputs
              (dataImported)="handleImportedData($event)"
            ></app-import-cm-inputs>
          </div>

          @if (!calculationModuleService.waitingCM$()) {
            @for (input_cat of inputs_categories; track $index) {
              @if (input_cat.contains_component) {
                <ul uk-accordion>
                  <li [ngClass]="{ 'uk-open': input_cat.id == '0' }">
                    <h3 class="uk-accordion-title input_title" href="#">{{ input_cat.name }}</h3>
                    <div class="uk-accordion-content">
                      @for (component of getComponentFiltered(input_cat.id); track $index) {
                        <div class="component uk-margin">
                          <span
                            [matTooltip]="component.input_description"
                            matTooltipPosition="above"
                            matTooltipClass="multiline-tooltip"
                            >{{ component.input_name }} - (value: {{ component.input_value
                            }}{{ component.input_unit == '' ? '' : ' ' }}{{ component.input_unit }})
                            <div class="uk-form-controls">
                              @if (
                                component.input_type == type_input ||
                                component.input_type == type_range
                              ) {
                                <input
                                  class="uk-{{ component.input_type }}"
                                  id="{{ component.input_parameter_name }}"
                                  value="{{ component.input_value }}"
                                  type="{{ component.input_type }}"
                                  min="{{ component.input_min }}"
                                  max="{{ component.input_max }}"
                                  placeholder="{{ component.input_name }}"
                                  (change)="changeValueFromInput($event, component)"
                                />

                                <span class="values">
                                  Min: {{ component.input_min }}{{ component.input_unit }}; Max:
                                  {{ component.input_max }}{{ component.input_unit }};
                                </span>
                              } @else if (component.input_type == type_radio) {
                                @for (value of component.input_default_value; track $index) {
                                  <label style="cursor: pointer"
                                    ><input
                                      class="uk-{{ component.input_type }}"
                                      name="{{ component.input_name }}"
                                      id="{{ component.input_name }}"
                                      value="{{ value.input }}"
                                      type="{{ component.input_type }}"
                                      (change)="changeValueFromInputArray(value, component)"
                                      [checked]="component.input_value == value"
                                    />
                                    {{ value }}</label
                                  >
                                }
                              } @else if (component.input_type == type_select) {
                                <select
                                  class="uk-{{ component.input_type }}"
                                  (change)="
                                    changeValueFromInputArray($any($event.target).value, component)
                                  "
                                >
                                  @for (value of component.input_default_value; track $index) {
                                    <option
                                      value="{{ value }}"
                                      selected="{{
                                        component.input_value == value ? 'selected' : ''
                                      }}"
                                    >
                                      {{ value }}
                                    </option>
                                  }
                                </select>
                              }
                            </div>
                          </span>
                        </div>
                      }
                    </div>
                  </li>
                </ul>
              }
            }

            @if (layersFromType.length >= 1) {
              <div class="uk-margin">
                <ul uk-accordion>
                  <li class="uk-open">
                    <h3 class="uk-accordion-title">LAYER INPUTS</h3>
                    <div class="uk-accordion-content">
                      @for (layersTable of layersFromType; track $index) {
                        <div
                          [matTooltip]="
                            'Type: ' +
                            layersTable.data_type +
                            ' - ' +
                            layersTable.layerType +
                            '\nDescription: ' +
                            layersTable.type_description
                          "
                          matTooltipClass="multiline-tooltip"
                          matTooltipPosition="above"
                          class="layer-input-container"
                        >
                          <h5>
                            @if (layersTable.input_name != null) {
                              {{ layersTable.input_name }}
                            } @else {
                              {{ layersTable.type_description }}
                            }
                          </h5>

                          <select
                            class="uk-select"
                            [(ngModel)]="layersTable.layerSelected"
                            (change)="setLayerNeeded()"
                          >
                            @for (layer of layersTable.layers; track $index) {
                              @if (layer.category != 'Calculation module') {
                                <option [ngValue]="layer">
                                  <!-- @ToDo to be modified when generated layers will be handled -->
                                  {{ layer.name }}
                                </option>
                              }
                            }
                          </select>
                        </div>
                      }
                    </div>
                  </li>
                </ul>
              </div>
            }

            <button
              class="uk-button run-cm-button"
              [class.uk-button-primary]="!cmRunning"
              [class.uk-button-danger]="cmRunning"
              (click)="!cmRunning ? runCM() : stopCM()"
            >
              @if (!cmRunning) {
                <span class="stop-cm-text">Run CM</span>
              } @else {
                <span class="spinner-stop" uk-spinner="ratio: 1"></span>
                <span class="stop-cm-text">STOP CM</span>
              }
            </button>
          }
        </div>
      </div>
    }
  </div>
}
