import { Component, EventEmitter, Input, Output } from '@angular/core'
import { CalculationModulesComponent } from './calculation-modules/calculation-modules.component'

@Component({
  standalone: true,
  selector: 'app-cms-tab',
  templateUrl: './cms-tab.component.html',
  styleUrls: ['./cms-tab.component.css'],
  imports: [
    // Components
    CalculationModulesComponent,
  ],
})
export class CMsTabComponent {
  @Input() cmsOpen: boolean
  @Output() emitClose = new EventEmitter<void>()

  constructor() {}
}
