<span class="center">
  @if (!noIndicator && categoryDisplayed > 2) {
    <div style="padding: 10px">
      <select
        class="uk-select"
        (ngModelChange)="changeResultsDisplay($event)"
        [(ngModel)]="selectedButton"
      >
        @for (button of dropdown_btns; track $index) {
          @if (button.display) {
            <option [selected]="button.ref === selectedButton.ref" [ngValue]="button">
              {{ button.name }}
            </option>
          }
        }
      </select>
    </div>
  }
</span>

<ul class="uk-tab" data-uk-tab="{connect:'#my-id3'}">
  <li id="tab1" (click)="tabSwitch(tab1)">
    <a href="#">INDICATORS</a>
  </li>
  <li id="tab2" [class.uk-disabled]="result.graphics.length === 0" (click)="tabSwitch(tab2)">
    <a href="#">CHARTS</a>
  </li>
</ul>
<ul id="my-id3" class="uk-switcher results-container">
  <li>
    @if (result.indicators) {
      <div>
        <app-summary-result
          [summaryResult]="result.indicators"
          [refSelected]="selectedButton.ref"
        ></app-summary-result>
      </div>
    }
    @if (indicatorLoading || indicatorPersoLoading) {
      <div class="spinner" uk-spinner="ratio: 3"></div>
    }
  </li>
  <li class="charts-tab">
    @if (result.graphics) {
      <div>
        @for (graphic of result.graphics; track $index) {
          <div>
            @if (graphic.category == 'energy_mix') {
              <app-electricity-mix
                [datasets]="graphic.data"
                [labels]="graphic.labels"
                [isLoading]="graphic.isLoading"
              ></app-electricity-mix>
            }
            @if (graphic.category == cm_category) {
              <app-chart
                [datasets]="graphic.data"
                [type]="graphic.type"
                [title]="graphic.name"
                [labels]="graphic.labels"
                [options]="graphic.options"
                [isLoading]="graphic.isLoading"
              ></app-chart>
            }
          </div>
        }
      </div>
    }
  </li>
</ul>
<div>
  <app-export-data
    [graphics]="result.graphics"
    [indicators]="result.indicators"
    [refSelected]="selectedButton.ref"
    [graphState]="graphicsExportButtonState"
    [indicatorState]="indicatorExportButtonState"
    [tabSelected]="tabSelected"
  ></app-export-data>
</div>
