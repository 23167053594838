import { Pipe, PipeTransform } from '@angular/core'
import { layerCategory } from '../layers-tab.interfaces'

@Pipe({
  name: 'filterCatWithName',
  standalone: true,
})
export class FilterCatWithNamePipe implements PipeTransform {
  constructor() {}

  transform(categories: layerCategory[]): layerCategory[] {
    return categories.filter((category) => category.name !== '') || []
  }
}
