import { environmentCitiwattsBase } from '@env/environment.ctwa'

export const environmentCitiwattsDev = {
  ...environmentCitiwattsBase,
  apiUrl: 'https://api.citiwattsdev.hevs.ch/api',
  geoserverUrl: 'https://geoserver.citiwattsdev.hevs.ch/geoserver/hotmaps/wms',
  keycloakUrl: 'https://auth.citiwattsdev.hevs.ch',
  keycloakRealm: 'citiwatts-dev',
}

export const environment = environmentCitiwattsDev
