import { Pipe, PipeTransform } from '@angular/core'
import { LayerClass } from '../layers-interaction/layers-interaction.class'
import { layerCategory } from '../layers-tab.interfaces'

@Pipe({
  name: 'filterLayerOnCategory',
  standalone: true,
})
export class filterLayerOnCategoryPipe implements PipeTransform {
  constructor() {}

  transform(layers: LayerClass[], category: layerCategory): any[] {
    // Cat
    return layers.filter((layer) => {
      if (category.name === layer.category && layer.category != '' && layer.layer_type != 'heat2')
        // @ToDo: Fix heat2 hard coded filter-->
        return layer
    })
  }
}
