/*
  Data used in the Feedback form as option for issue type and level dropdown menus
*/

export const ISSUES_LEVELS = [
  { name: 'low', id: 1 },
  { name: 'normal', id: 2 },
  { name: 'high', id: 3 },
]
export const ISSUE_TYPE = [
  { name: 'Bug', id: 1 },
  { name: 'Question', id: 2 },
  { name: 'Enhancement', id: 3 },
]
