-
@if (expanded) {
  <div [@visibleTrigger]="'visible'" class="details">
    <div style="position: relative">
      @if (layer.isLoading) {
        <div class="custom-tooltip">{{ layer.name }} layer is loading</div>
      }
      <button
        type="button"
        [class.wait]="interactionService.summaryResultState"
        name="{{ layer.workspaceName }}"
        class="uk-button uk-button-default uk-width-1-1 button-layer"
        (click)="showRemoveLayer()"
        onclick="/*@ToDo matomo*/ /*_paq.push(['trackEvent', 'Layer', 'Layer Button Click', getAttribute('name')]);*/"
        [class.uk-button-secondary]="layer.isSelected"
      >
        <input class="uk-checkbox" type="checkbox" [checked]="layer.isSelected" />
        {{ layer.name }}
        @if (layer.isLoading) {
          <div class="spinner" uk-spinner="ratio:0.5"></div>
        }
      </button>
    </div>
    @if (layer.isSelected) {
      <app-layer-tool [layer]="layer"></app-layer-tool>
    }
  </div>
}
