import { DatePipe } from '@angular/common'
import { Component, OnInit, signal } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import {
  SnapshotConfig,
  SnapshotService,
} from '@components/_panel-left/tools-tab/snapshot/snapshot.service'
import { LoaderService } from '@services/loader.service'

@Component({
  standalone: true,
  selector: 'app-snapshot-panel',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.less'],
  imports: [
    // Form
    FormsModule,
    ReactiveFormsModule,

    // Pipes
    DatePipe,
  ],
  providers: [SnapshotService],
})
export class SnapshotComponent implements OnInit {
  snapshots$ = signal<SnapshotConfig[]>([])
  newSnapshot: string
  newOpen = false
  isLogged$ = signal<boolean>(false)

  constructor(
    private _snapshotService: SnapshotService,
    private _loaderService: LoaderService,
  ) {}

  async ngOnInit() {
    this.refresh()
  }

  refresh() {
    this._snapshotService.list().then((snapshots) => this.snapshots$.set(snapshots))
  }

  apply(snapshot: SnapshotConfig) {
    this._loaderService.display(true)
    this._snapshotService.apply(snapshot, () => {
      this._loaderService.display(false)
    })
  }

  delete(snapshot: SnapshotConfig) {
    this._snapshotService.delete(snapshot).then(() => this.refresh())
  }

  openSave() {
    this.newOpen = true
  }

  closeSave() {
    this.newOpen = false
  }

  save() {
    this._snapshotService.add(this.newSnapshot, '').then((success) => {
      if (success) {
        this.newSnapshot = ''
        this.refresh()
      }
    })
  }
}
