<div class="uk-card uk-card-default uk-overflow-container">
  <table class="uk-table" [style.width.px]="tblBody.offsetWidth">
    <thead>
      <tr>
        <th>Filename</th>
        <th>Layer</th>
        <th>Share</th>

        <th>Size</th>
        <th></th>
        <th class="uk-text-left" colspan="3">
          <i class="iButton" (click)="getFiles()">&#8635;</i>
        </th>
      </tr>
    </thead>
  </table>

  <div class="uk-overflow-auto uk-height-medium">
    <table class="uk-table uk-table-hover uk-table-striped" #tblBody>
      <tbody>
        @for (upFile of uploadedFiles; track upFile.id) {
          <tr>
            <td class="wrap-all">
              {{ upFile.name }}
            </td>
            <td>
              {{ upFile.layerName }}
            </td>
            <td>
              @if (upFile.shared == 'true') {
                <p>Shared</p>
              } @else {
                <p>Private</p>
              }
            </td>
            <td class="uk-text-left">{{ upFile.size | number: '1.1-1' }} MB</td>
            <td class="uk-text-center">
              <i class="flaticon-import iButton" (click)="download(upFile)"></i>
            </td>
            <td class="uk-text-center">
              <i class="flaticon-trash iButton" (click)="openConfirmationDialog(upFile)"></i>
            </td>
          </tr>
        }
      </tbody>
    </table>
  </div>
  <div class="uk-grid-small uk-margin-small" uk-grid id="tableUpload">
    <div class="uk-width-1-2@s">
      <label for="layer-type">Select the layer type : </label>
      <select class="uk-select" [(ngModel)]="selectedLayer" id="layer-type">
        @for (layer of layers$(); track layer.id) {
          <option [ngValue]="layer">[{{ layer.category }}] {{ layer.name }}</option>
        }
      </select>
    </div>

    <div class="uk-width-1-2@s">
      <div class="uk-width-1-1@s" uk-form-custom="target: true">
        <label for="layer-file">Select your layer file (.tif or .csv) : </label>
        <input
          id="layer-file"
          type="file"
          accept=".tif,.csv"
          (change)="onFileChange($event)"
          #inputFile
        />
        <input class="uk-input" type="text" placeholder="Select file" #inputFile2 />
      </div>
    </div>

    <div class="uk-width-1-2@s info-box">
      <div class="uk-width-1-1@s">
        <input type="checkbox" class="checkbox" id="share-layer-checkbox" [(ngModel)]="shared" />
        <label
          for="share-layer-checkbox"
          matTooltip="If checked, your layer will be available to users of your organization."
          matTooltipPosition="above"
        >
          Click here to share your layer
          <a style="position: relative; top: -2px" uk-icon="icon: info;"></a>
        </label>
      </div>

      @if (isFileOk) {
        <p>Size: {{ file2Up.size / 1000 | number: '1.1-1' }} KB</p>
      }
    </div>

    <div class="uk-width-1-2@s">
      <button
        class="uk-button uk-button-primary uk-width-1-1@s"
        [disabled]="!isFileOk || !selectedLayer"
        (click)="fileUpload()"
        uk-icon="upload"
      >
        @if (isUploading) {
          <span uk-spinner></span>
        } @else {
          <span>Upload layer</span>
        }
      </button>
    </div>
  </div>
</div>
