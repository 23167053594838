import { Component } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog'

@Component({
  selector: 'app-layer-delete-confirm-popup',
  standalone: true,
  imports: [
    //Material
    MatDialogModule,
    MatButtonModule,
  ],
  templateUrl: './layer-delete-confirm-popup.component.html',
  styleUrl: './layer-delete-confirm-popup.component.css',
})
export class LayerDeleteConfirmPopupComponent {
  constructor(private _dialogRef: MatDialogRef<LayerDeleteConfirmPopupComponent>) {}

  save() {
    this._dialogRef.close(true)
  }

  close() {
    this._dialogRef.close(false)
  }
}
