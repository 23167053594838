import { AfterContentInit, Component } from '@angular/core'
import { Title } from '@angular/platform-browser'
import { LoaderService } from '@core/services/loader.service'
import { environment } from 'environments/environment'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterContentInit {
  /**
   * management of initial status of general loading
   * AppComponent own general loading
   */
  showLoader: boolean
  favIcon: HTMLLinkElement = document.querySelector('#favicon')

  constructor(
    private _loaderService: LoaderService,
    _titleService: Title,
  ) {
    //Title and favicon add dynamically
    _titleService.setTitle(environment.appName + ' - Toolbox')
    this.favIcon.href = environment.favicon
  }

  ngAfterContentInit(): void {
    this.notifyLoaderService()
  }
  /**
   * subscribe the status of the loader service
   * AppComponent own general loading
   */
  notifyLoaderService() {
    this._loaderService.getStatus().subscribe((val: boolean) => {
      this.showLoader = val
    })
  }
}
