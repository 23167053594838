import { animate, state, style, transition, trigger } from '@angular/animations'
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core'
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms'
import { ToasterService } from '@core/services/toaster.service'
import { RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha'
import { feedbackExpendedState } from './feedback.interface'
import { FeedbackService } from './feedback.service'
import { ISSUES_LEVELS, ISSUE_TYPE } from './issue.data'

@Component({
  standalone: true,
  selector: 'app-feedback',
  templateUrl: 'feedback.component.html',
  styleUrls: ['feedback.component.css'],
  animations: [
    trigger('feedbackTrigger', [
      state('expanded', style({ opacity: 1 })),
      state('collapsed', style({ opacity: 0 })),
      transition('collapsed => expanded', animate('200ms 150ms linear')),
      transition('expanded => collapsed', animate('100ms linear')),
    ]),
  ],
  providers: [FeedbackService],
  imports: [
    // Forms
    ReactiveFormsModule,

    // Captcha
    RecaptchaModule,
    RecaptchaFormsModule,
  ],
})
export class FeedbackComponent implements OnInit {
  @ViewChild('file_input') file_input: ElementRef

  @Input({
    required: true,
  })
  expandedState: feedbackExpendedState

  @Output() closeForm = new EventEmitter<void>()

  captcha: string | null = null
  captchaError = false

  feedbackForm: FormGroup
  feedbackLoader = false
  fileName: string = ''

  issue_levels = ISSUES_LEVELS
  issue_type = ISSUE_TYPE

  submitted = false
  submitMessage = {
    msg: '',
    success: false,
  }

  constructor(
    private _feedbackService: FeedbackService,
    private _formBuilder: FormBuilder,
    private _toasterService: ToasterService,
  ) {}

  ngOnInit() {
    this._createForm()
  }

  close(event: Event) {
    event.preventDefault()
    this.closeForm.emit()
  }

  onCaptchaResolved(captchaResponse: string) {
    this.captcha = captchaResponse
    this.captchaError = false
  }

  onCaptchaExpired() {
    this.captcha = null
    this.captchaError = true
  }

  onUploadFile(event: Event) {
    const input = event.target as HTMLInputElement
    if (input.files && input.files[0]) {
      const file = input.files[0]
      if (this._isImage(file)) {
        this.fileName = file.name
        this.feedbackForm.patchValue({ file })
      } else {
        this._toasterService.showDangerToaster('Uploaded file is not a valid image.')
      }
    }
  }

  submitFeedbackForm() {
    this.feedbackLoader = true

    if (!this.feedbackForm.valid || !this.captcha) {
      this.captchaError = true
      this.feedbackLoader = false
      return
    }

    const fd = new FormData()
    const formValue = this.feedbackForm.value

    if (formValue.file) {
      fd.append('file', formValue.file, this._removeSpecialCharacters(formValue.file.name))
    }

    fd.append('name', formValue.name)
    fd.append('email', formValue.mail)
    fd.append('company', formValue.company)
    fd.append('feedback_type', formValue.type.name)
    fd.append('feedback_priority', formValue.level.name)
    fd.append('title', formValue.title)
    fd.append('description', formValue.description)
    fd.append('captcha', this.captcha)

    this._feedbackService
      .sendFeedback(fd)
      .then((val) => {
        this._toasterService.showToaster(val.message)
        this.submitMessage.msg =
          'Your request has been successfully sent to administrators.<br /> Thanks for your feedback!'
        this.submitMessage.success = true
        this._resetForm()
      })
      .catch(() => {
        this._showError()
        this.submitMessage.msg =
          'Something went wrong.<br/>Please try to send a new request or contact an administrator'
        this.submitMessage.success = false
      })
    this.feedbackLoader = false
  }

  private _createForm() {
    this.feedbackForm = this._formBuilder.group({
      name: [''],
      mail: ['', [Validators.required, Validators.email]],
      company: [''],
      type: [null, Validators.required],
      level: [null, Validators.required],
      title: ['', Validators.required],
      description: ['', Validators.required],
      isAccepted: [false, Validators.requiredTrue],
      file: [null],
    })
  }

  private _isImage(file) {
    const mimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/bmp', 'image/webp']
    return mimeTypes.includes(file.type)
  }

  private _removeSpecialCharacters(str: string) {
    return str.replace(/[^a-zA-Z0-9\s._-]/g, '')
  }

  private _resetForm() {
    this.feedbackForm.reset({
      name: '',
      mail: '',
      company: '',
      type: null,
      level: null,
      title: '',
      description: '',
      isAccepted: false,
      file: null,
    })
    this.fileName = ''
    this.captcha = null
    this.captchaError = false
  }

  private _showError() {
    this._toasterService.showDangerToaster(
      'Unable to send the issue! Please, try later or send a mail to administrator',
    )
  }
}
