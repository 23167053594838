import { NgClass } from '@angular/common'
import { Component, EventEmitter, Input, Output, signal } from '@angular/core'
import { AuthService } from '@core/services/auth.service'
import { MapComponent } from '@pages/map/map.component'
import { SelectionToolComponent } from './selection-tools/selection-tool.component'
import { SnapshotComponent } from './snapshot/snapshot.component'

@Component({
  standalone: true,
  selector: 'app-tools-tab',
  templateUrl: './tools-tab.component.html',
  styleUrls: ['./tools-tab.component.css'],
  imports: [
    NgClass,

    // Components
    SnapshotComponent,
    SelectionToolComponent,
  ],
})
export class ToolsTabComponent {
  @Input() toolsOpened: boolean
  @Output() emitClose = new EventEmitter<void>()

  isLoggedIn$ = signal<boolean>(false)

  constructor(
    public mapComponent: MapComponent,
    private _authService: AuthService,
  ) {}

  ngOnInit() {
    this._fetchInformation()
  }

  private async _fetchInformation() {
    this.isLoggedIn$.set(await this._authService.isLoggedIn())
  }
}
