import { Component, Inject } from '@angular/core'
import { MatButtonModule } from '@angular/material/button'
import {
  MAT_DIALOG_DATA,
  MatDialogContent,
  MatDialogModule,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog'
import { environment } from 'environments/environment'

@Component({
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatDialogTitle, MatDialogContent],
  selector: 'app-cookies',
  templateUrl: './cookies.component.html',
  styleUrls: ['./cookies.component.scss'],
})
export class CookiesComponent {
  appName = environment.appName

  constructor(
    private _dialogRef: MatDialogRef<CookiesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { cookiesVersion: string },
  ) {}

  close() {
    this._dialogRef.close(false)
    localStorage.setItem('cookiesAccepted', this.data.cookiesVersion)
  }
}
