import { Component } from '@angular/core'
import { MatTooltipModule } from '@angular/material/tooltip'
import { APP_NAME } from '@core/constants/constant.data'
import { STANDALONE_CMS } from './stand-alone-cms.data'

@Component({
  standalone: true,
  selector: 'app-stand-alone-cms',
  templateUrl: './stand-alone-cms.component.html',
  styleUrls: ['./stand-alone-cms.component.css', '../calculation-modules.component.css'],
  imports: [
    // Material
    MatTooltipModule,
  ],
})
export class StandAloneCMsComponent {
  // temp fix for coollife cm
  standaloneCMs = STANDALONE_CMS.filter((cms) => cms.project.includes(APP_NAME))
}
