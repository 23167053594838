/* ******************************************************************
 * * Copyright         : 2024 HES-SO Valais-Wallis - Institute of Informatics - EASILab
 * * Description       :
 * * Revision History  :
 * * Date           Author                              Comments
 * * ---------------------------------------------------------------------------
 * * 19.07.2017     Lesly Houndole - CREM               Creation
 * *
 ******************************************************************/

import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Directive } from '@angular/core'
import { LoaderService } from '@core/services/loader.service'
import { ToasterService } from '@core/services/toaster.service'
import { isNullOrUndefinedString } from '@services/core.utilities'
import { timeout } from 'rxjs/operators'

@Directive()
export class BaseService {
  public headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  })

  constructor(
    protected http: HttpClient,
    protected loaderService: LoaderService,
    protected toasterService: ToasterService,
  ) {}

  handleError(error: any) {
    this.loaderService.display(false)
    let message

    if (isNullOrUndefinedString(error.message)) {
      message = 'An internal error occurred'
    } else {
      console.warn('BaseService/handleError', error.message)
      message = error.message
      message = ', ' + message
      const statusText = error.statusText
      message = statusText + ' ' + message
    }

    this.toasterService.showDefaultErrorToaster(error.status)
    return Promise.reject(error.message || error)
  }

  POST(payload, url): Promise<any> {
    let options = {
      headers: this.headers,
    }
    return this.http
      .post(url, JSON.stringify(payload), options)
      .pipe(timeout(200000))
      .toPromise()
      .then((response) => response)
      .catch(this.handleError.bind(this))
  }

  POSTunStringify(payload, url, options?): Promise<any> {
    if (!options) {
      options = {
        headers: this.headers,
      }
    }
    return this.http
      .post(url, payload, options)
      .pipe(timeout(200000))
      .toPromise()
      .then((response) => response)
      .catch(this.handleError.bind(this))
  }

  GET(url): any {
    return this.http.get(url)
  }

  DELETE(url, options?) {
    if (!options) {
      options = {}
    }
    return this.http.delete(url, options)
  }

  async pGET(url): Promise<any> {
    return await this.http
      .get(url)
      .toPromise()
      .then((response) => response)

      .catch(this.handleError.bind(this))
  }

  public async getJSONFromFille(url: string): Promise<any> {
    return this.http
      .get(url)
      .toPromise()
      .then((response) => response)
  }
}
